import { Component } from '@angular/core'
import { Events, Platform } from '@ionic/angular'

import { SplashScreen } from '@ionic-native/splash-screen/ngx'
import { StatusBar } from '@ionic-native/status-bar/ngx'
import { BackgroundMode } from '@ionic-native/background-mode/ngx'
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx'

import { Storage } from '@ionic/storage';
import { ConfigService } from './services/config.service'
import { PushService } from './services/push.service'
import { AdmobService } from './services/admob.service'
import { UpdateService } from './services/update.service'
import { NetworkService } from './services/network.service'
import { HttpService } from './services/http.service'
import { Badge } from '@ionic-native/badge/ngx'

declare var cordova: any;
declare var window: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  private isCamera: any
  private bgTime:number = 0

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private updates: UpdateService,
    private pushService: PushService,
    private backgroundMode: BackgroundMode,
    private network: NetworkService,
    private events: Events,
    private screenOrientation: ScreenOrientation,
    private badge: Badge,
    private admobService: AdmobService,
    private configService: ConfigService,
    private http:HttpService,
    private storage:Storage
  ) {
    if (this.platform.is('android')) {
      this.isCamera = window.localStorage.getItem("cameraActive")
      document.addEventListener('resume', (data: any) => {
        // Does not get called
        console.log("addeventlistener platform.resume", data)
        if (data && this.isCamera == "true") {
          this.configService.goToChat = true
          this.handleAndroidCameraRestart(data)
        }
      }, false);
    }
    this.configService.parseLanguageFile()
    this.initializeApp()
  }

  handleAndroidCameraRestart(event: any) {
    console.log("handleAndroidCameraRestart", event)
    if (event && event.pendingResult) {
      const status: string = event.pendingResult.pluginStatus !== null ? '' : event.pendingResult.pluginStatus.toUpperCase();

      if (event.pendingResult.pluginServiceName == "Camera") {
        if (
          event.pendingResult.pluginStatus == "No Result" ||
          event.pendingResult.pluginStatus == "Error"
        ) this.events.publish("captureCameraRestartError", event)
        else {
          console.log("OK - send image to chat after restart", event)
          window.localStorage.setItem("cameraActive", "false")
          window.localStorage.setItem("androidCameraFail", JSON.stringify(event))
        }
      }
    }
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT).catch(err => {/*console.log("can´t lock screen orientation")*/ });
      // start app on mobile
      //console.log("App is running on: ", this.platform.platforms())
      if (!this.platform.is("desktop")) {
        //this.statusBar.styleDefault()
        this.statusBar.styleLightContent()

        //############## PUSH & UPDATES ##############
        this.pushService.start()
        //this.updates.checkUpdates()


        //############## BACKGROUND MODE ##############
        this.backgroundMode.setDefaults({
          //title: "BetterApp",
          //text: "Waiting for new messages",
          silent: true
        })
        //this.backgroundMode.overrideBackButton();
        this.backgroundMode.enable();

        this.backgroundMode.on('deactivate').subscribe((event) => {
          setTimeout(() => { this.updates.checkUpdates() }, 650)
          //this.events.publish('newMsg', {})
          //if (this.platform.is("ios")) this.network.startUpdates()
          if(!this.configService.settings.encryptionActive) this.network.startUpdates()
          this.badge.clear()
          this.pushService.dismissAllPush()

          // delete all messages if timer is out of range
          this.configService.checkForTime()

          //check if app was longer thang 2 minutes in background
          //const bgTime = window.localStorage.get("backgroundTime")
          /*console.log("backgound mode DEACTIVATED")
          console.log("date now",Date.now())
          console.log("this.bgTime",this.bgTime)
          console.log("this.bgTime+(1000*60*2)",this.bgTime+(1000*60*2))
          console.log("IS LONGEWR TAHN 2 MINTES",Date.now() >= this.bgTime+(1000*60*2))
          if( Date.now() >= this.bgTime+(1000*60*2)){
            const dbReady = this.configService.checkForDbReady()
            if(dbReady) console.log("STORAGE is ready",dbReady)
            else{
              console.log("STORAGE is NOT ready",dbReady)
              window.location.href = "/tabs/tab1"
            }
          }*/
        })
        this.backgroundMode.on('activate').subscribe((event) => {
          this.backgroundMode.disableWebViewOptimizations()
          this.updates.stop()
          this.network.updatesStarted = false
          //window.localStorage.setItem("backgroundTime",Date.now() )
          this.bgTime = Date.now()
        })

        //############## NETWORK CONNECTION ##############
        //if(this.platform.is("ios"))this.network.startUpdates()
        //else this.network.start()

      } else {
        //this.updates.start("high") // started app on desktop
      }

      this.badge.clear()
      if(this.pushService.dismissAllPush) this.pushService.dismissAllPush()


      this.admobService.start()

      //all done hide splashScreen but ... check for restart if camera is true
      if (!this.configService.goToChat)
        this.splashScreen.hide()

      /*this.platform.resume.subscribe((data: any) => {
        console.log("app component | paltform resume ", data)

        if (!this.configService.checkHash()) return
        if (this.platform.is("ios")) this.network.startUpdates()
        else this.network.start()
      })*/

    });
  }

}
