import { Injectable } from '@angular/core'
import { UpdateService } from './update.service'
import { ConfigService } from './config.service'
import { Events, Platform } from '@ionic/angular'

@Injectable({
  providedIn: 'root'
})
export class AdmobService {

  public updatesStarted: boolean = false

  constructor(
    private updates: UpdateService,
    private configService: ConfigService,
    private platform: Platform
  ) { }

  start() {
  }
}
