import { Injectable } from '@angular/core'
import { Events } from '@ionic/angular'
import { Observable, of, throwError } from 'rxjs'
import { forkJoin, Subscription } from 'rxjs'
import { catchError, tap, map } from 'rxjs/operators'
import { ConfigService } from './config.service'
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class FriendListService {

  private showAddFriend: any = true
  public friends: any
  public friendRequest: any
  public token: string

  private subscibtion: Subscription
  private interval: any

  constructor(
    private configService: ConfigService,
    private http: HttpClient,
    public events: Events
  ) {
  }



  _approve_friend(_friend, _pubKey) {
    this.moveFirst(_friend, false)
    this.changeFriendSettings(_friend, "accept", true, false)
    this.changeFriendSettings(_friend, "pubKey", _pubKey, true)
  }

  _delete_friend(_friend) {
    var index = this.configService.friendList.indexOf(this.configService.friendList.find(friendList => friendList.username === _friend));
    if (index > -1) { this.configService.friendList.splice(index, 1); }
    this.configService.storage_save("friendList", this.configService.friendList, false)
  }


  //################ HELPER #########################

  // push new user to first place in friendlist
  public pushFirst(_valToPush, _targetArray) {
    return [_valToPush].concat(_targetArray)
  }

  // move existing friend to top of friendlist
  public moveFirst(_friend, _save) {
    var friendList = this.configService.friendList
    var first = friendList.find(friendList => friendList.username === _friend)
    this.configService.friendList.sort(function (x, y) { return x == first ? -1 : y == first ? 1 : 0; })
    if (_save) this.configService.storage_save("friendList", friendList, false)
  }

  //delete from friendrequest
  private deleteFriend(_friend) {

  }

  // change friend settings like accepted or icon
  public changeFriendSettings(_friend, _key, _val, _save) {
    var friendList = this.configService.friendList
    for (var i in friendList)
      if (friendList[i].username == _friend) {
        friendList[i][_key] = _val
        break
      }
    this.configService.friendList = friendList
    if (_save) this.configService.storage_save("friendList", friendList, false)
    return true
  }

  // refresh friendlist 
  public refreshFriendList(hasCallback, callback) {
    this.friends = []
    this.friendRequest = []
    var data = this.configService.friendList
    if(!data) return this.getNewFriendList()
    for (var i in data) {
      if(!this.eliminateDuplicates(this.friendRequest, data[i].username)) continue
      if(!this.eliminateDuplicates(this.friends, data[i].username)) continue
      if (data[i].accept) this.friends.push(data[i])
      else this.friendRequest.push(data[i])
    }
    if (!hasCallback) return
    let friendList = { friends: this.friends, friendRequest: this.friendRequest }
    return callback(friendList)
  }
  private getNewFriendList(){
    console.dir("ERROR | No friendList found ... try to get new one from server")
    if(!this.configService.username) {
      this.configService.getUsername()
      return this.getNewFriendList()
    } 
    var sendData = {username: this.configService.username}
    this.http.post("https://server.betterapp.de/getFriendList" , sendData)
      .subscribe(
        res => {
          console.dir("got new friendList ->",res)
          this.configService.friendList = res
          this.events.publish("refreshFriendlist")
        }
        , err => {
          console.error("getFriendList ERR->", err)
        }
      )
  }
  private eliminateDuplicates(arr, _username) {
    if(arr.length == 0) return true
    for(var i in arr) 
      if(arr[i].username == _username) return false
      else return true
  }

  public getUserPgP(_username: string) {
    for (var i in this.configService.friendList) {
      if (this.configService.friendList[i].username == _username) return this.configService.friendList[i].pubKey
    }
  }
}
