import { Injectable } from '@angular/core'
import { Network } from '@ionic-native/network/ngx'
import { UpdateService } from './update.service'
import { ConfigService } from './config.service'

@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  public updatesStarted: boolean = false

  constructor(
    private network: Network,
    private updates: UpdateService,
    private configService: ConfigService,
  ) { }

  start() {
    console.log('start network watch!');

    // watch network for a disconnection
    let disconnectSubscription = this.network.onDisconnect().subscribe(() => {
      console.log('network was disconnected :-(');
      this.updates.stop()
      this.updatesStarted = false
    });

    // stop disconnect watch
    //disconnectSubscription.unsubscribe();

    // watch network for a connection
    let connectSubscription = this.network.onConnect().subscribe(() => {
      console.log('Network connected! Now waiting for wifiState...');
      // We just got a connection but we need to wait briefly
      // before we determine the connection type. Might need to wait.
      // prior to doing any api requests as well.
      setTimeout(() => {
        this.configService.wifiState = this.network.type
        console.log('we got a ' + this.configService.wifiState + ' connection, woohoo!');
        if (!this.configService.settings.encryptionActive)
          this.startUpdates()
      }, 3000);
    });

    // stop connect watch
    //connectSubscription.unsubscribe();

  }

  startUpdates() {
    if (!this.configService.username || this.updatesStarted) return
    if (
      this.configService.wifiState === 'wifi' ||
      this.configService.wifiState === 'ethernet'
    ) {
      this.updates.start("high")
    } else {
      this.updates.start("medium")
    }
    this.updatesStarted = true
  }

  checkConnection() {
    console.log("check connection")
    let connectSubscription = this.network.onConnect().subscribe(() => {
      let networkState = this.network.type

      var states = {};
      states['unknown'] = 'Unknown connection';
      states['ethernet'] = 'Ethernet connection';
      states['wifi'] = 'WiFi connection';
      states['cell_2g'] = 'Cell 2G connection';
      states['cell_3g'] = 'Cell 3G connection';
      states['cell_4g'] = 'Cell 4G connection';
      states['cell'] = 'Cell generic connection';
      states['none'] = 'No network connection';

      let isConnected = states[networkState] == 'Unknown connection' || states[networkState] == 'No network connection' ? false : true
      console.log("check connection end | states[networkState],isConnected", networkState, states[networkState], isConnected)
      return {
        connectionType: states[networkState],
        isConnected: isConnected
      }

      connectSubscription.unsubscribe()
    })
  }

}
