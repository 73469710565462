import { Injectable } from '@angular/core'
import * as OpenPgP from 'openpgp'
import { Base64 } from 'js-base64'
import { ConfigService } from './config.service'
import { HttpService } from './http.service'
import { FriendListService } from './friend-list.service'
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class PgpService {

  private privkey: any
  private passphrase: any
  private privKeyObj: any
  public pubKeyObj: any

  constructor(
    private configService: ConfigService,
    private request: HttpService,
    private list: FriendListService,
    private http: HttpClient
  ) {
    OpenPgP.config.compression = OpenPgP.enums.compression.zlib
  }

  ngOnInit() {
  }

  start(_cb){
    this.configService.storage_get('privkey').then(async(data) => {
      if (!data) return _cb(false)
      this.privkey = Base64.decode(data)
      this.privKeyObj = (await OpenPgP.key.readArmored(this.privkey)).keys[0]
      this.configService.storage_get('token').then((data) => {
        var mixToken = data.split("-")
        mixToken = window.localStorage.getItem("mixToken")
        var secret = this.passphrase = mixToken + mixToken + mixToken + mixToken
        this.privKeyObj.decrypt(secret)
        return _cb(true)
      })
    })
    this.configService.storage_get('pubkey').then(async(data) => {
      if (!data) return
      const pubkey = Base64.decode(data)
      this.pubKeyObj = (await OpenPgP.key.readArmored(pubkey)).keys[0]
      console.log("PGP | got pubkey", this.pubKeyObj)
    })
  }

  create_keypair(secret: string, username: string, callback: any) {
    var options = {
      userIds: [{ name: username }], // multiple user IDs
      numBits: 2048,                 // RSA key size
      passphrase: secret            // protects the private key
    }
    OpenPgP.generateKey(options).then((key) => {
      var privkey = key.privateKeyArmored // '-----BEGIN PGP PRIVATE KEY BLOCK ... '
      this.configService.storage_save("privkey", Base64.encode(privkey), false)
      var pubkey = key.publicKeyArmored   // '-----BEGIN PGP PUBLIC KEY BLOCK ... '
      this.configService.storage_save("pubkey", Base64.encode(pubkey), false)
      var revocationCertificate = key.revocationCertificate // '-----BEGIN PGP PUBLIC KEY BLOCK ... '
      this.configService.storage_save("revokeKey", Base64.encode(revocationCertificate), false)
      callback(key, Base64.encode(pubkey))
    })
  }

  async encrypt_msg(msg: string, reciever: string, callback: any) {
    let errorData = {
      err: true,
      type: undefined,
      message: undefined,
      stack:undefined
    }
    let options
    console.log("encrypt_msg | msg,reciever",msg,reciever)
    if(!msg || !reciever || !callback) {
      errorData.type = 'noData'
      errorData.message ="PGP-ENCRYPT-MESSAGE-ERROR --> msg, reciver or callback --> undefined !!"
      errorData.stack = JSON.stringify({ 
          msg: msg,
          reciever: reciever,
          callback: callback,
        })
      this.handleError(errorData)
      return callback(errorData)
    }

    const pubkey = Base64.decode(this.list.getUserPgP(reciever))
    //console.log("encrypt_msg | pubkey",pubkey)
    if(!pubkey) return callback(false)

    try {
      options = {
        message: await OpenPgP.message.fromText(msg),                         // CleartextMessage or Message object
        publicKeys: (await OpenPgP.key.readArmored(pubkey)).keys,             // for encryption
      }
      //console.log("encrypt_msg | options",options)
    } catch (err) {
      console.error("PGP | enceryption Error from TryCatch | creating OPTIONS",err)        
      errorData.type = 'readPgpKey'
      errorData.message ="PGP-ENCRYPT-MESSAGE-ERROR --> can not create message or public key data!"
      errorData.stack = JSON.stringify({
        err: err,
        pubkey: pubkey,
        options: options
      })
      this.handleError(errorData)
      return callback(errorData)
    }
    try{
      console.log("OpenPgP.encrypt START")
      OpenPgP.encrypt(options).then((ciphertext) => {                   // CREATE -> '-----BEGIN PGP SIGNED MESSAGE ... END PGP SIGNATURE-----'
        console.log("OpenPgP.encrypt ciphertext",ciphertext)
        return callback(Base64.encode(ciphertext.data))
      }).catch((err)=>{
        console.error("PGP | enceryption Error",err)
        errorData.type = 'enceryption'
        errorData.message ="PGP-ENCRYPT-MESSAGE-ERROR --> PgP-Error @ encrypting with pubkey | OpenPgp function"
        errorData.stack = JSON.stringify({
          err: err,
          pubkey: pubkey,
          options: options
        })
        this.handleError(errorData)
        return callback(errorData)
      })
    } catch (err) {
      console.error("PGP | enceryption Error from TryCatch | encrypt msg",err)        
      errorData.type = 'enceryption'
      errorData.message ="PGP-ENCRYPT-MESSAGE-ERROR --> PgP-Error @ encrypting with pubkey | tryCatch"
      errorData.stack = JSON.stringify({
        err: err,
        pubkey: pubkey,
        options: options
      })
      this.handleError(errorData)
      return callback(errorData)
    }
  }

  async decrypt_msg(_msg: string, callback: any) {
    let errorData = {
      err: true,
      type: undefined,
      message: undefined,
      stack:undefined
    }
    if(!_msg || !callback) {
      errorData.type = 'noData'
      errorData.message ="PGP-DECRYPT-MESSAGE-ERROR --> msg or callback --> undefined !!"
      errorData.stack = JSON.stringify({ 
          msg: _msg,
          callback: callback,
        })
      this.handleError(errorData)
      return callback(errorData)
    }

    try {
      var options = {
        message: await OpenPgP.message.readArmored(Base64.decode(_msg)),        // parse encrypted bytes
        privateKeys: [this.privKeyObj],                                         // for decryption
        publicKeys: this.pubKeyObj,                                             // for verification (optional)
      }
    } catch (err) {
      errorData.type = 'options'
      errorData.message ="PGP-DECRYPT-MESSAGE-ERROR --> can not create options to decrypt!"
      errorData.stack = JSON.stringify({
        err: err,
        options: options
      })
      this.handleError(errorData)
      return callback(errorData)
    }

    try {
      console.log("PGP | OpenPgP.decrypt",options)  
      await OpenPgP.decrypt(options).then(function (plaintext) {
        return callback(plaintext.data)
      }).catch((err)=>{
        console.log("PGP | deceryption Error",err)      
        errorData.type = 'decryption'
        errorData.message ="PGP-DECRYPT-MESSAGE-ERROR --> PgP-Error @ decrypting with privKey | OpenPgP Native"
        errorData.stack = JSON.stringify({
          err: err,
          options: options
        })
        this.handleError(errorData)
        return callback(errorData)
      })
    } catch (err) {
      errorData.type = 'decryption'
      errorData.message ="PGP-DECRYPT-MESSAGE-ERROR --> PgP-Error @ decrypting with privKey | OpenPgP tryCatch"
      errorData.stack = JSON.stringify({
        err: err,
        options: options
      })
      this.handleError(errorData)
      return callback(errorData)
    }

  }

  async revoke_keypair() {
    var privkey = await this.configService.storage_get("privkey")
    var options = {
      key: OpenPgP.key.readArmored(privkey).keys[0]
    }
    OpenPgP.revokeKey(options).then(function (key: any) {
      var pubkey = key.publicKeyArmored   // '-----BEGIN PGP PUBLIC KEY BLOCK ... '
    })
  }

  
  handleError(error: any): void {
    console.log("got error",error)
    var sendData = {
      err: {
        msg: error.message,
        stack: error.stack,
        fullData: error
      },
      username: (this.configService && this.configService.username) ? this.configService.username : "noUser"
    }

      this.http.post('https://server.betterapp.de/saveError', sendData)
        .subscribe(
          res => {
            if (!res) console.log("Error NOT saved!")
            console.log("Error saved!")
          }
          , err => {
            console.error("request ERR->", err)
          }
        )
  }
}
