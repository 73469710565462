import { Injectable } from '@angular/core';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx'
import { ConfigService } from './config.service'
import { UpdateService } from './update.service'
import { HttpService } from './http.service'
import { Push, PushObject, PushOptions } from '@ionic-native/push/ngx'
import { BackgroundMode } from '@ionic-native/background-mode/ngx'
import { Badge } from '@ionic-native/badge/ngx'

import { Events, Platform } from '@ionic/angular'

@Injectable({
  providedIn: 'root'
})
export class PushService {

  private username: string

  public dismissAllPush: any

  private options:PushOptions = {
    android: {
      "senderID": "853760595385"
    },
    ios: {
      alert: 'true',
      badge: true,
      sound: 'true'
    },
    windows: {},
    browser: {
      pushServiceURL: 'http://push.api.phonegap.com/v1/push'
    }
  }

  private pushObject: PushObject

  constructor(
    private push: Push,
    private configService: ConfigService,
    private localNotifications: LocalNotifications,
    private backgroundMode: BackgroundMode,
    private updateService: UpdateService,
    private badge: Badge,
    private platform:Platform,
    private request: HttpService,
    public events: Events,
  ) {
  }

  start() {
    this.configService.storage_get("username").then(data => {
      this.username = data

      // to check if we have permission
      this.push.hasPermission()
        .then((res: any) => {
          if (res.isEnabled) {
            console.log('We have permission to send push notifications');
          } else {
            console.log('We do not have permission to send push notifications');
          }
          this.checkForPush()

          this.events.subscribe("resetPushToken",()=>{
            console.log(">>>>> reset pushToken <<<<<")
            window.localStorage.removeItem("gcmKey")
            this.checkForPush()
          })
        }).catch(err => console.log("NO PUSH !", err));

    })
  }

  checkForPush() {
    this.pushObject = this.push.init(this.options);

    this.pushObject.on('notification').subscribe((notification: any) => {
      console.log('Received a notification', notification)
      console.log('backgroundMode isActive', this.backgroundMode.isActive())
      if (this.platform.is('android') && this.backgroundMode.isActive()) {
        /*this.localNotifications.schedule({
          id: 1,
          text: 'New Message!',
          foreground: true,
          sound: this.configService.settings.sound.file
        });*/
        this.badge.increase(1);
      }
      //this.configService.gotNewNotification = true
      //if(!this.configService.settings.encryptionActive)
      //  this.updateService.checkUpdates()

      /*pushObject.finish(
        'push-1'
      );*/
    });

    this.pushObject.on('registration').subscribe((registration: any) => {
      console.log('Device registered', registration)
      this.configService.gcmKey = registration
      var gcmKey: any = window.localStorage.getItem("gcmKey")
      let saveKey = false
      if (!gcmKey) {
        saveKey = true
      } else {
        gcmKey = JSON.parse(gcmKey)
        if (gcmKey.registrationId != registration.registrationId) {
          saveKey = true
        }
      }

      //if(saveKey){
        window.localStorage.setItem("gcmKey", JSON.stringify(registration))
        this.savePushtoken()
      //}
    });

    this.pushObject.on('error').subscribe(error => {
      console.error('Error with Push plugin', error)
    });

    //pushObject.clearAllNotifications()
    this.dismissAllPush = this.pushObject.clearAllNotifications

    if(!this.pushObject) return false
    return true
  }

  savePushtoken(){
    if(!this.configService.username) return console.log("NO username set -- not logged in --- do not send Pushtoken")
    if(this.platform.is('desktop')) return console.log("DESKTOP --- do not send Pushtoken")
    this.request.sendRequest({ gcmKey: this.configService.gcmKey.registrationId , username: this.configService.username }, "/saveGcmKey", "", "", (data) => {
      console.log("saveGcmKey res",data)
    })
  }

}
