import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, of, throwError } from 'rxjs'
import { forkJoin, Subscription } from 'rxjs'
import { catchError, tap, map } from 'rxjs/operators'
import { ConfigService } from './config.service'
import { Events } from '@ionic/angular'

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  private requests: any
  private lastAction: number
  private username: string
  private isOnline: boolean = false
  private interval: any
  private subscibtion: Subscription

  //link preview
  private links: any
  private text: string = ""

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    public events: Events
  ) {
    this.requests = []
    this.configService.storage_get("username").then(data => {
      this.configService.username = data
      setTimeout(() => {
        this.getChat()
      }, 5000);
    })

    this.events.subscribe('saveSettings', (data) => {
      this.saveSettings(data.command, data.msg)
    });
  }

  //###########################################################################
  //################              Http Request                  ###############
  //###########################################################################


  /**
   * Send HTTP request to server and save the time msg was send
   * After success return the data fromn the response
   * After error start erroh handling
   * 
   * @example
   * sendRequest({text:"text"}, "/sendChatMsg", "friend-username", "")
   * 
   * @param {object} _obj Object containing the data to send
   * @param {number} _url The api url to send to
   * @param {number} _to Username to send data to
   * @param {number} _command Used to add or delete
   * @returns The HTTP response
   */
  sendRequest(_obj: object, _url: string, _to: string, _command: string, callback: any) {
    if (!this.checkDataToBeValid_Idiot(_obj, _url, _to, _command)) callback(false)
    var postData;
    if (_command == "login") {
      postData = _obj
    } else {
      postData = { from: this.configService.username, command: _command, to: _to, data: _obj }
    }
    //this.presentToast("serverRequest","Please wait for server to answer ...","","bottom",2000)
    //this.http.setDataSerializer("json");
    //this.http.post("http://server.betterapp.de" + _url, postData)
    this.http.post("https://server.betterapp.de" + _url, postData)
      .subscribe(
        res => {
          //console.dir(res);
          callback(res);
          this.removeDataFromQuery({ postData: postData, url: _url, to: _to, command: _command })
        }
        , err => {
          this.daFuck_Oo_ItsAnHttpRequestError(err)
          return callback(false);
        }
      )
    this.addRequestToQuery({ postData: postData, url: _url, to: _to, command: _command })
    this.lastAction = Date.now()
  }

  addRequestToQuery(_data) {
    const hasData = this.requests.find(el => el === _data)
    if (hasData) return
    this.requests.push(_data)
  }
  removeDataFromQuery(_data) {
    for (var key in this.requests)
      if (this.requests[key] == _data) delete this.requests[key]
  }
  /**
   * Handle the error for HTTP request if user has no conenction any more or any other error occured.
   * Save the last messages to send in RAM so wen can try to reconnect and send the data again
   * 
   * @example
   * requestErr(dataFromServer) 
   * 
   * @param {object} dataFromServer Object containing the data to send
   * @returns Nothing 
   */
  requestErr() {
    setTimeout(() => {
      ////console.log("this.requests.length", this.requests.length == 0?this.requests.length:this.requests);
      ////console.log("this.lastAction", this.lastAction);
      if (this.requests.length == 0) return
      ////console.log("setTmeout->this.requests.length", this.requests.length == 0?this.requests.length:this.requests);
      let holdRequests = this.requests;
      this.requests = [];
      for (var i in holdRequests) {
        if (holdRequests[i].url == "/login")
          this.configService.presentToast("serverRequest", this.configService.languageJson[this.configService.getLang()]["httpservice"]["retryAutoLogin"], "Server", "bottom", 2000)
        //console.log("holdRequests[i]", holdRequests[i])
        this.sendRequest(
          holdRequests[i].postdata,
          holdRequests[i].url,
          holdRequests[i].to ? holdRequests[i].to : "",
          holdRequests[i].command ? holdRequests[i].command : "",
          (postData: any) => {
            console.log("sended old request", postData)
            //this.configService.presentToast("serverRequest", "SUCCESS - send old request", "Server", "bottom", 2000)
            //this.checkOldCommand(postData)
          }
        )
      }
    }, 5000)
  }

  checkOldCommand(postData) {
    if (postData.err) return //console.log("error checkOldCommand", postData)

    if (postData.msg.token) this.configService.goTo("/tabs/tab2");
  }



  //###########################################################################
  //###########################################################################
  //################              The NEW and f4ncY               #############
  //################              HTTP error handling             #############
  //################                	  SYSTEM                    #############
  //###########################################################################
  //###########################################################################

  checkDataToBeValid_Idiot(_obj: object, _url: string, _to: string, _command: string) {
    let checkPass = true
    const objType = typeof _obj
    const urlType = typeof _url
    const toType = typeof _to
    const commandType = typeof _command
    //console.log("checkDataToBeValid_Idiot | _obj,_url,_to,_command",_obj,_url,_to,_command)
    //console.log("checkDataToBeValid_Idiot | objType,urlType,toType,commandType",objType,urlType,toType,commandType)
    if (objType != "object") checkPass = false
    if (urlType != "string") checkPass = false
    if (toType != "string") checkPass = false
    if (commandType != "string") checkPass = false
    //console.log("Request data has right format?",checkPass)
    for (var key in _obj) {
      //console.log("Request Obj nr: "+key, "type of el-> "+(typeof _obj[key]))
      if (typeof _obj[key] === 'undefined') {
        checkPass = false
        
      }
    }
    //console.log("Obect data is not undefined?",checkPass)
    return checkPass
  }

  daFuck_Oo_ItsAnHttpRequestError(err) {
    console.error("daFuck_Oo_ItsAnHttpRequestError ERR->", err)
    this.requestErr()
    this.http.post('https://server.betterapp.de/saveError',{
      err:{
        msg:"HTTP-ERROR",
        stack:err
      }, 
      username:this.configService ? this.configService.username : "noUser" 
    })
    .subscribe(
      res => {
        if(!res) console.log("Error NOT saved!")
        console.log("Error saved!")
      }
      , err => {
        console.error("request ERR->", err)
      }
    )
  }



  //###########################################################################
  //################              CHECK 4 UPDATES                ###############
  //###########################################################################

  updates(): Observable<any> {
    //console.log("updates",this.configService.username)
    return this.http.post("https://server.betterapp.de/checkForUpdates", { username: this.configService.username }).pipe(
      //tap(res=>//console.log("post checkForUpdates",res)),
      map((res: any) => {
        var updates = { reason: [], data: [] }
        this.isOnline = true
        if (res.results.length == 0) return updates
        for (var i in res.results) {
          updates.reason.push(res.results[i].reason)
          updates.data.push(res.results[i].data)
          this.sendRequest({ id: res.results[i]._id }, "/gotUpdate", "", "", data => {/*console.log("gotUpdate->", data)*/ })
        }
        return updates
      })
    )
  }


  //###########################################################################
  //################              CHECK 4 CHAT-MESSAGES         ###############
  //###########################################################################

  getChat(): Observable<any> {
    //console.log("getChat", this.configService.username)
    return this.http.post("https://server.betterapp.de/chats", { username: this.configService.username, check: false }).pipe(
      //tap(res => console.log("res /chats", res)),
      map((res: any) => {
        if (res.result.length == 0) return res
        this.http.post("https://server.betterapp.de/chats", { username: this.configService.username, check: true }).toPromise().then(data => {/*console.log("deleted chats from server", data)*/ })
        return res
      })
    )
  }

  //###########################################################################
  //################              CHECK 4 DOWNLOAD-FILES         ###############
  //###########################################################################

  getDownloads(): Observable<any> {
    //console.log("getDownloads", this.configService.username)
    return this.http.post("https://server.betterapp.de/files", { username: this.configService.username }).pipe(
      tap(res => console.log("res /files", res)),
      map((res: any) => {
        return res
      })
    )
  }

  //###############################################################
  //######################## SAVE SETTINGS ############################
  //###############################################################
  saveSettings(_command, _msg) {
    this.configService.storage_save("settings", this.configService.settings, false)
    this.sendRequest({ settings: this.configService.settings, newAvatar: _command == "newAvatar" ? true : false }, "/saveSettings", "", "", data => {
      if (data.err) return this.configService.presentToast("settings", this.configService.languageJson[this.configService.getLang()]["httpservice"]["saveSettingsError"], "", "bottom", 2000)
      this.configService.presentToast("settings", this.configService.languageJson[this.configService.getLang()]["httpservice"]["settingsSaved"] + (_msg ? _msg : ""), "", "bottom", 2000)
    })
    this.configService.settings = this.configService.settings
  }


  //###############################################################
  //######################## SAVE SETTINGS ############################
  //###############################################################
  getLinkPreview(_link): Observable<any> {
    return this.http.get('http://api.linkpreview.net/?key=5dd52e90073e59dff9ab17beb0a69734bac543900c536&q=' + _link).pipe(
      map((res: any) => {
        return res
      })
    )
  }

  //###############################################################
  //######################## CHECK PRO STATUS ############################
  //###############################################################
  checkProStatus(): Observable<any> {
    return this.http.get('https://server.betterapp.de/checkProStatus').pipe(
      map((res: any) => {
        return res
      })
    )
  }
    
  //###############################################################
  //######################## CHECK SERVER STATUS ############################
  //###############################################################
  sendPing(): Observable<any> {
    console.log("---> send ping to server <---")
    return this.http.get('https://server.betterapp.de/ping').pipe(
      map((res: any) => {
        console.log("---> send ping to server <--- ---> RESULT <---",res)
        if(!res) return false
        return true
      })
    )
  }
}
