import { Injectable } from '@angular/core'
import { ConfigService } from './config.service'
import { catchError, tap, map } from 'rxjs/operators'
import { HttpClient } from '@angular/common/http'
import { Base64 } from '@ionic-native/base64/ngx'
import { Observable, of, throwError } from 'rxjs'
import { Events } from '@ionic/angular'
import { File } from '@ionic-native/file/ngx';
import { Platform } from '@ionic/angular'
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { Base64ToGallery, Base64ToGalleryOptions } from '@ionic-native/base64-to-gallery/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';
import { HTTP } from "@ionic-native/http/ngx";
import { WebView } from '@ionic-native/ionic-webview/ngx';
//import { FileTransfer, FileUploadOptions, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { PgpService } from './pgp.service'

import * as Base64js from 'base64-js'
import { callbackify } from 'util'
import * as lzString from 'lz-string';

import * as CryptoJS from 'crypto-js';
//var base64js = require('base64-js')

@Injectable({
  providedIn: 'root'
})
export class MediaService {

  public canCapture: boolean = true
  public hasMedia: boolean = false
  private chunk_maxChunks: number = 0
  private chunk_current: number = 0
  private chunks: any
  public isUploading: boolean = false
  private lastChunk: number = 0
  private base64ChunkData: string = ""
  public canUpload: boolean = true

  public isDownloading: boolean = false
  private downloadQueue: any = []

  private storageLocation: string = ""

  private uploadContainer: any = []

  private base64Data: string = ""

  constructor(
    private configService: ConfigService,
    private http: HttpClient,
    private base64: Base64,
    private events: Events,
    private file: File,
    private platform: Platform,
    private fileOpener: FileOpener,
    private base64ToGallery: Base64ToGallery,
    private filePath: FilePath,
    private nativeHttp: HTTP,
    //private fileTransfer: FileTransfer,
    private pgpService: PgpService,
    private webView: WebView
  ) {

    this.platform.ready().then(() => {
      console.log("start media service")
      this.events.subscribe("gotBase64Data", (_fileName, _type, _friendName, _msgId, _b64Data) => {
        console.log("gotBase64Data", _fileName, _type, _friendName, _msgId, _b64Data)
        this.prepareBase64(_fileName, _type, _friendName, _msgId, _b64Data)
      })
    })
  }

  ngOnInit() {
  }

  //########################################################
  //#############        chunk-system        ###############
  //########################################################

  //get media file from camera
  createChunkFrombase64(_base64File, _friendName) {
    console.log("createChunkFrombase64 ImageData", _base64File, _friendName)
    this.canCapture = false
    this.isUploading = true
    const msgId = this.configService.createMsgId()
    window.localStorage.setItem("uploadMsgId", String(msgId))

    let encrypt = false
    if (this.configService.settings.encryptionActive)
      encrypt = true

    this.configService.storage_save("photo#" + msgId, _base64File, encrypt)
    this.addImageStorage(msgId, _friendName)

    this.pgpService.encrypt_msg(_base64File, _friendName, (encryptedData) => {
      console.log("createChunkFrombase64 | PGP ENCRYPT OK | encryptedData", encryptedData)
      this.chunkSubstr(encryptedData, 100000, () => {
        this.configService.userData_update(msgId, _friendName, "fileStatus", "finished")
        const dataHash = CryptoJS.MD5(encryptedData).toString()
        this.createUploadData(msgId, "photo", msgId, _friendName, dataHash)
      })
    })
    return msgId
  }

  addImageStorage(_msgId, _friendName) {
    let imageStorage: any = window.localStorage.getItem(_friendName + "-imageStorage") ? JSON.parse(window.localStorage.getItem(_friendName + "-imageStorage")) : []
    if (!imageStorage[0]) imageStorage = []
    imageStorage.push(_msgId)
    window.localStorage.setItem(_friendName + "-imageStorage", JSON.stringify(imageStorage))
  }

  //get media file from camera
  createMediaFromFilepath(_msgId, _filePath, _fileName, _type, _friendName, _providedMimeType, callback) {
    this.canCapture = false

    /*this.checkFileSize(_filePath, (fileSize) => {
      console.log("checkFileSize | fileSize", fileSize);
      if (!fileSize) {
        this.canCapture = true
        return callback(false)
      }*/
    _msgId = !_msgId ? this.configService.createMsgId() : _msgId

    if (!this.isUploading) {
      this.isUploading = true
      //encode media file from path with base 64
      //console.log("createMediaFromFilepath _filePath, _fileName, _type, _friendName, _providedMimeType")
      //console.log(_filePath, _fileName, _type, _friendName, _providedMimeType)
      //if (this.platform.is("ios")) {
        //console.log("PLATFORM is IOS ->", Base64js);
      //  this.fileReadAsBase64(_filePath, _fileName, _type, _friendName, _msgId)
      //} else {
        this.base64.encodeFile(this.webView.convertFileSrc(_filePath)).then((base64File: string) => {
          console.log("base64.encodeFile", base64File);
          if (!base64File) {
            try {
              const _events = this.events
              let xhr = new XMLHttpRequest();
              xhr.onload = () => {
                console.log("XHR reader.onload ", xhr);
                let reader = new FileReader();
                reader.onloadend = () => {
                  console.log("XHR reader.onloadend ", xhr, reader);
                  this.prepareBase64(_fileName, _type, _friendName, _msgId, reader.result)
                };
                reader.readAsDataURL(xhr.response);
              };
              xhr.open('GET', this.webView.convertFileSrc(_filePath));
              xhr.responseType = 'blob';
              xhr.send();
            } catch (error) {
              console.log("XHR ERRROOORRRR ", error);

            }
            return
          }
          this.prepareBase64(_fileName, _type, _friendName, _msgId, base64File)
        }).catch((err) => {
          console.log("error on fileUrlToBase64", err);
        });
      //}
    } else {
      console.info("ABORT UPLOAD - Already running ... adding to waiting list", _msgId)
      this.addToUploadList({
        msgId: _msgId,
        filePath: _filePath,
        fileName: _fileName,
        type: _type,
        friendName: _friendName,
        providedMimeType: _providedMimeType
      })
    }

    return callback(_msgId)
    //})
  }

  checkFileSize(_filePath, callback) {
    console.log("checkFileSize | _filePath 1", _filePath);
    if (!_filePath.includes("file://") && _filePath.includes("/private"))
      _filePath = "file://" + _filePath.split("/private")[1]
    //if (!_filePath.includes("file://") && _filePath.includes("content:/"))
    //_filePath = "file://" + _filePath.split("content:/")[1]
    /*if (!_filePath.includes("file://") && !_filePath.includes("content:/"))
    _filePath = "file://" + _filePath*/

    if (!_filePath.includes("file://") && !_filePath.includes("content:/"))
      _filePath = "file://" + _filePath

    console.log("checkFileSize | _filePath 2", _filePath);
    this.file.resolveLocalFilesystemUrl(_filePath).then((fileEntry) => {
      console.log("resolveLocalFileSystemURL | fileEntry", fileEntry);
      fileEntry.getMetadata((metadata) => {
        console.log("file size : " + (metadata.size / 1024 / 1024) + "mb");
        if ((metadata.size / 1024 / 1024) < 10) callback(true)
        else callback(false)
      });
    }).catch((err) => {
      console.log("checkFileSize | error", err)
      this.canCapture = true
      this.configService.presentToast("fileError", "Can´t open file", "", "bottom", 2000)
    })
  }

  addToUploadList(_uploadData) {
    let uploadContainer: any = window.localStorage.getItem("uploadArray")
    if (!uploadContainer || uploadContainer.length == 0) uploadContainer = []
    else uploadContainer = JSON.parse(uploadContainer)
    uploadContainer.push(_uploadData)
    window.localStorage.setItem("uploadArray", JSON.stringify(uploadContainer))
  }

  removeFromUploadList(_msgId) {
    let uploadContainer: any = window.localStorage.getItem("uploadArray")
    if (!uploadContainer || uploadContainer.length == 0) return
    else if(uploadContainer.length == 1) uploadContainer = []
    else
      for(var i in uploadContainer)
        if(uploadContainer[i].msgId == _msgId) 
        uploadContainer = this.removeArrayItemOnce(uploadContainer, uploadContainer[i])
    window.localStorage.setItem("uploadArray", JSON.stringify(uploadContainer))
  }
  removeArrayItemOnce(arr, value) {
    var index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }

  fileReadAsBase64(_filePath, _fileName, _type, _friendName, _msgId) { 
    console.info("fileReadAsBase64", _filePath, _fileName, _type, _friendName, _msgId)
    // split file path to directory and file name
    let fileName = _filePath.split('/').pop();
    let path = _filePath.substring(0, _filePath.lastIndexOf("/") + 1);
    //if (this.platform.is("ios")) {
    if (!path.includes("file://") && path.includes("/private"))
      path = "file://" + path.split("/private")[1]
    else if (!path.includes("file://"))
      path = "file://" + path
    if(path.includes("file:///"))
      path = "/"+path.split(":///")[1]
    console.log("fileReadAsBase64 - IOS - fileName,path", fileName, path)
    //this.file.readAsDataURL(this.webView.convertFileSrc(path), fileName)
    this.file.readAsDataURL(path, fileName)
      .then(base64File => {
        console.log("here is encoded file -----> ", base64File)
        this.prepareBase64(_fileName, _type, _friendName, _msgId, base64File)
      })
      .catch((err) => {
        console.log('Error reading file', err);
        this.canCapture = true
        this.removeFromUploadList(_msgId)
      })
  }

  prepareBase64(_name, _type, _friendName, _msgId, _base64File) {
    console.log("createMediaFromFilepath BASE64", _base64File)
    let fileSplit = _base64File.split("charset=utf-8;")
    _base64File = fileSplit[0] + fileSplit[1]
    window.localStorage.setItem("uploadMsgId", String(_msgId))

    let encrypt = false
    if (this.configService.settings.encryptionActive)
      encrypt = true
    this.configService.storage_save(_type + "#" + _msgId, _base64File, encrypt)
    this.addImageStorage(_msgId, _friendName)

    this.pgpService.encrypt_msg(_base64File, _friendName, (encryptedData) => {
      console.log("createMediaFromFilepath BASE64 AS PGP", encryptedData)
      this.chunkSubstr(encryptedData, 100000, () => {
        const dataHash = CryptoJS.MD5(encryptedData).toString()
        this.createUploadData(_msgId, _type, _name, _friendName, dataHash)
        this.addFileStorage(_msgId, _name, _friendName, _type)
      })
    })
  }

  addFileStorage(_msgId, _fileName, _friendName, _type) {
    let fileStorage: any = window.localStorage.getItem(_friendName + "-" + _type) ? JSON.parse(window.localStorage.getItem(_friendName + "-" + _type)) : []
    if (!fileStorage[0]) fileStorage = []
    fileStorage.push({ msgId: _msgId, fileName: _fileName })
    window.localStorage.setItem(_friendName + "-" + _type, JSON.stringify(fileStorage))
  }

  //split base64 in 195kb chunks and save in array
  chunkSubstr(str, size, callback) {
    console.log("str length before compression", str.length)
    //str = lzString.compress(str)
    //console.log("str length after compression",str.length)
    const maxChunks = Math.ceil(str.length / size)
    if (maxChunks > 165) {
      console.log("chunkSubstr | error | filesize ... more than 165 chunks")
      this.canCapture = true
      return this.configService.presentToast("fileError", "Can´t open file", "", "bottom", 2000)
    }
    const chunks = new Array(maxChunks)
    for (let i = 0, o = 0; i < maxChunks; ++i, o += size) {
      chunks[i] = str.substr(o, size)
    }
    /*if (chunks.length >= 250) {
      console.log("Max filesize reached")
      this.configService.presentToastWithOptions("fileSize", "Max 50mb filesize allowed!", "", "middle",{})
      this.canUpload = true
      return callback(false)
    }*/
    this.chunks = chunks
    //console.log("chunks", this.chunks)
    this.chunk_maxChunks = maxChunks
    this.chunk_current = 0
    callback()
  }





  //########################################################
  //##########        fileUpload-system        #############
  //########################################################

  //upload split data ... wait for server to save 100kb part 
  //then upload next 100kb part
  createUploadData(_msgId, _type, _fileName, _friendName, _dataHash) {
    if (!_msgId || !_type || !_fileName || !_friendName) return this.configService.presentToast("uploadError", "Error: False upload data...", "", "middle", 2000)

    if (!this.canUpload) {
      this.canUpload = true
      return
    }
    this.http.post("https://server.betterapp.de/prepare_mediaChunk", {
      username: this.configService.username,
      friendName: _friendName,
      msgId: _msgId,
      type: _type,
      fileName: _fileName,
      dataHash: _dataHash
    }).subscribe((response: any) => {
      if (response.err) console.log("error on upload", response)
      this.uploadData(_msgId, 0, this.chunk_maxChunks, _friendName)
    })
  }

  uploadData(_msgId, _currentChunk, _maxChunks, _friendName) {
    console.log("uploadData", _msgId, _currentChunk, _maxChunks, _friendName)
    //encrypt with pgp
    //this.pgpService.encrypt_msg(this.chunks[_currentChunk],_friendName,(encryptedChunk)=>{
    this.http.post("https://server.betterapp.de/upload_mediaChunk", {
      chunk: this.chunks[_currentChunk],
      //chunk: encryptedChunk,
      currentChunk: _currentChunk,
      currentChunkHash: CryptoJS.MD5(this.chunks[_currentChunk]).toString(),
      msgId: _msgId,
    }).subscribe((response: any) => {
      if (response.err) console.log("error on upload", response)
      if (response.resend) return this.uploadData(_msgId, _currentChunk, _maxChunks, _friendName)
      console.log("uploadData - uploading chunk->" + this.chunk_current, this.chunk_maxChunks)
      if (_currentChunk == _maxChunks) {
        this.completeUpload(_msgId, _friendName, _maxChunks)
        return
      }
      _currentChunk += 1
      window.localStorage.setItem("currentChunk_upload", String(_currentChunk))
      this.events.publish("upload_status", { status: Math.round((_currentChunk / _maxChunks) * 100), msgId: _msgId });
      return this.uploadData(_msgId, _currentChunk, _maxChunks, _friendName)
    },
      (err) => {
        console.log("error uploadData", err)
        this.resumeUpload()
      },
      () => {
        //console.log("finished uploadData")
      })
    //})
  }

  completeUpload(_msgId, _friendName, _maxChunks) {
    this.http.post("https://server.betterapp.de/upload_mediaChunk_done",
      {
        msgId: _msgId,
        username: this.configService.username,
        friendName: _friendName,
      })
      .subscribe((response: any) => {
        if (response.err) return console.error("ERROR: upload_mediaChunk_done", this)
        if (response.resend) {
          this.chunk_current = 0
          return this.uploadData(_msgId, 0, _maxChunks, _friendName)
        }
        this.events.publish("upload_done")
        this.configService.storage_remove("currentUpload")
        window.localStorage.removeItem("currentChunk_upload")
        window.localStorage.removeItem("uploadMsgId")
        this.isUploading = false
        this.canCapture = true
        this.chunk_current = 0
        this.chunks = []

        this.configService.userData_update(_msgId, _friendName, "fileStatus", "finished")
        this.checkForNextUpload()
      })
  }

  resumeUpload() {
    let currentChunk = Number(window.localStorage.getItem("currentChunk_upload"))
    //console.log("retry upload | current chunk-> " + currentChunk)
    this.configService.storage_get("currentUpload").then((uploadData) => {
      this.chunks = uploadData.chunks
      this.isUploading = true
      this.canCapture = false
      this.uploadData(this.chunks[this.chunk_current], currentChunk, uploadData.maxChunks, uploadData.friendName)
    })
  }

  checkForNextUpload() {
    let uploadContainer: any = window.localStorage.getItem("uploadArray") ? window.localStorage.getItem("uploadArray") : undefined
    if (!uploadContainer) return
    console.log("uploadContainer", uploadContainer)
    try {
      uploadContainer = JSON.parse(uploadContainer)
      if (uploadContainer.length == 0) return

      window.localStorage.setItem("uploadMsgId", String(uploadContainer[0].msgId))
      this.createMediaFromFilepath(
        uploadContainer[0].msgId,
        uploadContainer[0].filePath,
        uploadContainer[0].fileName,
        uploadContainer[0].type,
        uploadContainer[0].friendName,
        uploadContainer[0].mimeType,
        (msgId) => console.log("No msgId created -> same as before", msgId)
      )
      uploadContainer.shift()
      window.localStorage.setItem("uploadArray", JSON.stringify(uploadContainer))
    } catch (err) {
      console.log("ERROR@-> checkForNextUpload", err, uploadContainer)
    }

  }


  //########################################################
  //##########        fileDownload-system        ###########
  //########################################################

  //save data to download
  createNewDownload(_msgId, _friendName, _maxChunks, _type, _fileName, _dataHash) {
    console.log("create new download", _msgId, _friendName, _maxChunks, _type, _fileName, _dataHash)
    if (!_msgId || !_type || !_fileName || !_friendName) return this.configService.presentToast("uploadError", "Error: False download data...", "", "middle", 2000)
    let downloadData = {
      timeStamp: Date.now(),
      downloadId: _msgId,
      currentChunk: 0,
      maxChunks: _maxChunks,
      friendName: _friendName,
      type: _type,
      fileName: _fileName,
      dataHash: _dataHash
    }
    this.configService.storage_save("download-" + _msgId, downloadData, false)
    if (this.isDownloading) {
      console.log("##############################################################")
      console.log("download already running! queue->" + _msgId)
      console.log("##############################################################")
      return this.addToDownloadQueue(_msgId)
    }
    this.isDownloading = true
    this.startDownload(0, _maxChunks, _msgId, _friendName)
    //this.configService.userData_update(msgId, "checkmark", "downloading", false, friendName)
  }

  addToDownloadQueue(_msgId) {
    const findMsgId = this.downloadQueue.find(function (element) {
      return element == _msgId;
    })
    if (findMsgId) return console.log("MsgId already in download waiting list ->", _msgId)
    this.downloadQueue.push(_msgId)
  }

  startDownload(_startChunk, _maxChunks, _msgId, _friendName) {
    window.localStorage.setItem("currentDownloadMsgId", _msgId)
    window.localStorage.setItem("currentDownloadChunk-" + _msgId, String(0))
    window.localStorage.setItem("currentDownloadFriendName-" + _msgId, _friendName)
    window.localStorage.setItem("maxDownloadChunk-" + _msgId, String(_maxChunks))
    this.downloadData(_startChunk, _maxChunks, _msgId, _friendName)
  }

  //download split data ... wait for server to send 100kb part 
  //then save 100kb part and continue if max is not reached
  downloadData(_currentChunk, _maxChunks, _msgId, _friendName) {
    //console.log("downloadData | _currentChunk, _maxChunks, _msgId |", _currentChunk, _maxChunks, _msgId)
    if (
      _currentChunk != 0 &&
      this.lastChunk == _currentChunk &&
      _currentChunk != _maxChunks
    ) {
      console.warn("WARNING -> last chunk again [_currentChunk,lastChunk] - abort action", _currentChunk, this.lastChunk)
      this.isDownloading = false
      this.checkDownloadQueue()
      return
    }
    else console.info("CHUNKS OK - _currentChunk, this.lastChunk", _currentChunk, this.lastChunk)

    if (!_friendName) {
      _friendName = window.localStorage.getItem("currentDownloadFriendName-" + _msgId)
      if (!_friendName) {
        console.log("ERROR | DOWNLOAD has no friendName | _currentChunk, _maxChunks, _msgId, _friendName", _currentChunk, _maxChunks, _msgId, _friendName)
        this.isDownloading = false
        this.checkDownloadQueue()
        this.deleteDownload(_msgId)
        return
      }
    }

    this.lastChunk = _currentChunk
    if (_currentChunk == undefined || !_maxChunks || !_msgId) return console.error(" ERROR: downloadData empty->_currentChunk, _maxChunks, _msgId", _currentChunk, _maxChunks, _msgId)
    this.http.post("https://server.betterapp.de/download_mediaChunk", {
      currentChunk: _currentChunk,
      msgId: _msgId
    }).subscribe(async (response: any) => {
      if (response.err) console.error("error on download", response)
      if (_currentChunk == _maxChunks) {

        //get last downloaded chunks
        this.base64Data = await this.mergeDownloadedChunks(_msgId, _currentChunk, _maxChunks)
        //base64Data = lzString.decompress(base64Data)

        //check base64 data to match hashed data
        this.configService.storage_get("download-" + _msgId).then(async (dlData) => {
          if (CryptoJS.MD5(this.base64Data).toString() != dlData.dataHash) {
            //delete current chunks
            let i = 0
            while (i < _currentChunk) {
              window.localStorage.removeItem("downloadChunk-" + _currentChunk + "-" + _msgId)
              i++
            }
            //set currentChunk to 0
            _currentChunk = 0
            //start download again
            return this.downloadData(_currentChunk, _maxChunks, _msgId, _friendName)
          }

          await this.pgpService.decrypt_msg(this.base64Data, async (decryptedData) => {

            //check for undefined after base64 end
            const testForUndefined = decryptedData.slice(decryptedData.length - 9, decryptedData.length)
            if (testForUndefined == "undefined")
              decryptedData = decryptedData.slice(0, decryptedData.length - 9)

            this.base64Data = decryptedData


            if (this.configService.settings.encryptionActive)
              this.base64Data = await this.configService.encrypt(this.base64Data)

            //update user message storage with completed chunks (eg set image/video/data)
            this.configService.storage_get("download-" + _msgId).then((downloadData) => {

              if (!downloadData) {
                this.removeDownloadData(_msgId, _maxChunks, _friendName)
                return console.error("no download data available")
              }
              if (downloadData.type == "photo") {
                this.configService.storage_save("photo#" + _msgId, this.base64Data, false)
                this.addImageStorage(_msgId, _friendName)
              } else if (downloadData.type == "video") {
                this.configService.storage_save("video#" + downloadData.fileName + "#" + _msgId, this.base64Data, false)
                this.addFileStorage(_msgId, downloadData.fileName, _friendName, "video")
              } else if (downloadData.type == "img") {
                this.configService.storage_save("img#" + downloadData.fileName + "#" + _msgId, this.base64Data, false)
                this.addFileStorage(_msgId, downloadData.fileName, _friendName, "img")
              } else {
                this.configService.storage_save("file#" + downloadData.fileName + "#" + _msgId, this.base64Data, false)
                this.addFileStorage(_msgId, downloadData.fileName, _friendName, "file")
              }

              this.base64Data = ""

              this.removeDownloadData(_msgId, _maxChunks, _friendName)
            })

            this.lastChunk = 0

          })
        })

      } else {
        //window.localStorage.setItem("downloadChunk-" + _currentChunk + "-" + _msgId, response.chunk)
        //decrypt chink with pgp
        //this.pgpService.decrypt_msg(response.chunk,(decryptedChunk)=>{
        this.configService.storage_save("downloadChunk-" + _currentChunk + "-" + _msgId, response.chunk, false)
        //this.configService.storage_save("downloadChunk-" + _currentChunk + "-" + _msgId, decryptedChunk, false)
        _currentChunk += 1
        window.localStorage.setItem("currentDownloadChunk-" + _msgId, String(_currentChunk))
        this.events.publish("download_status", { status: Math.round((_currentChunk / _maxChunks) * 100), msgId: _msgId });
        this.downloadData(_currentChunk, _maxChunks, _msgId, _friendName)
        //})
      }

    },
      (err) => {
        console.error("error downloadingData", err)
        //this.resumeUpload()
      },
      () => {
        //console.log("finished downloadingData")
      })
  }

  async mergeDownloadedChunks(_msgId, _currentChunk, _maxChunks) {
    let chunk = 0
    this.base64ChunkData = ""
    for (chunk; chunk < _maxChunks; chunk++) {
      await this.configService.storage_get("downloadChunk-" + chunk + "-" + _msgId).then((data) => {
        if (!data || data === null || data === undefined) return console.warn("can´t merge chunk - no data", _msgId, _currentChunk, _maxChunks)
        return this.base64ChunkData += data
      })
    }
    return this.base64ChunkData.replace("undefined", "")
  }

  removeDownloadData(_msgId, _maxChunks, _friendName) {
    //remove download from server
    this.http.post("https://server.betterapp.de/download_mediaChunk_done", { msgId: _msgId })
      .subscribe((response: any) => {
        if (response.err) return console.error("could not remove download from server", response.err)
        this.setDownloadCompleted(_friendName, _msgId)
        console.log("download removed from server", response)
      })
    this.base64ChunkData = ""
    const lastSavedChunk = Number(window.localStorage.getItem("lastSavedChunk"))
    let chunk = 0
    if (lastSavedChunk)
      chunk = lastSavedChunk
    //remove downloaded chunks from localStorage
    for (chunk; chunk <= _maxChunks; chunk++) {
      this.configService.storage_remove("downloadChunk-" + chunk + "-" + _msgId)
    }
    //remove download chunk info from localStorage
    window.localStorage.removeItem("currentDownloadMsgId")
    window.localStorage.removeItem("lastSavedChunk")
    window.localStorage.removeItem("maxDownloadChunk-" + _msgId)
    window.localStorage.removeItem("currentDownloadChunk-" + _msgId)
    window.localStorage.removeItem("currentDownloadFriendName-" + _msgId)
    //remove download info from storage
    this.configService.storage_remove("download-" + _msgId)

    //set to false for next download
    this.isDownloading = false
    this.base64ChunkData = ""
    this.events.publish("download_done", _msgId)
    this.setDownloadCompleted(_friendName, _msgId)
    setTimeout(() => {
      this.checkDownloadQueue()
    }, 2500);
  }

  setDownloadCompleted(_friendName, _msgId) {
    console.info("set download status on userData ->", _friendName, _msgId)
    this.configService.userData_update(_msgId, _friendName, "fileStatus", "finished")
  }

  resumeDownload() {
    let currentDownloadMsgId = window.localStorage.getItem("currentDownloadMsgId")
    if (!currentDownloadMsgId || currentDownloadMsgId == "") return
    const currentChunk = Number(window.localStorage.getItem("currentDownloadChunk-" + currentDownloadMsgId))
    let maxDownloadChunk = Number(window.localStorage.getItem("maxDownloadChunk-" + currentDownloadMsgId))
    let currentDownloadFriendName = Number(window.localStorage.getItem("currentDownloadFriendName-" + currentDownloadMsgId))
    //console.log("retry download | current chunk-> " + currentChunk)
    //console.log("retry download | maxDownload Chunk-> " + maxDownloadChunk)
    this.startDownload(currentChunk, maxDownloadChunk, currentDownloadMsgId, currentDownloadFriendName)
  }

  checkDownloadQueue() {
    console.log("checkDownloadQueue", this.downloadQueue)
    if (this.downloadQueue.length != 0) {
      this.configService.storage_get("download-" + this.downloadQueue[0]).then((data) => {
        if (!data) {
          this.downloadQueue.shift()
          this.checkDownloadQueue()
          return console.log("checkDownloadQueue - no data found", this.downloadQueue)
        }
        this.startDownload(0, data.maxChunks, this.downloadQueue[0], data.friendName)
        this.downloadQueue.shift()
      })
    }
  }



  //######################################################
  //##############      Save Data       ##################
  //######################################################

  async saveFile(_base64File, _name) {
    // imagen = data:image/jpeg;base64,/9j/4........
    let fileExtension = _name.split(".")
    fileExtension = fileExtension[fileExtension.length - 1]
    console.log("fileExtension", fileExtension)

    //this.checkFileExtension(fileExtension,(mimeType)=>{
    this.checkFileExtension(fileExtension).subscribe((mimeType) => {
      if (!mimeType || mimeType == "") return this.configService.presentToast("dataSaveError", this.configService.languageJson[this.configService.getLang()]["mediaservice"]["cantFindFileType"], "", "middle", 2000)

      const filePrefix = this.checkForImageOrVideo(mimeType)
      console.log("filePrefix", filePrefix)
      //if (filePrefix) return this.saveToGallery(_base64File, filePrefix, fileExtension)
      if (filePrefix && filePrefix == "img_") return this.saveToGallery(_base64File, filePrefix, fileExtension)
      //if (filePrefix && filePrefix == "vid_") return this.download(_base64File, _name,mimeType)

      let realData = _base64File.split(",")[1];
      let blob = this.b64toBlob(realData, mimeType);

      this.saveFileToDisk(blob, _name)
    })
  }

  saveFileToDisk(_blob, _name) {
    this.storageLocation = this.file.externalRootDirectory
    if (this.platform.is("ios")) this.storageLocation = this.file.documentsDirectory /*+ '/NoCloud/'*/
    //if (this.platform.is("ios")) storageLocation = this.file.syncedDataDirectory

    const iosStorageLocation = this.storageLocation + 'BetterApp/' + _name

    this.file.checkDir(this.storageLocation, 'BetterApp')
      .then(_ => {
        //console.log("checkFileDir -> OK")
        this.file.writeFile(this.storageLocation + 'BetterApp/', _name, _blob).then(response => {
          //console.log("save base64blob success", response)
          //if (this.platform.is("ios")) return this.iosOpenFile(iosStorageLocation, mimeType, _name)
          this.configService.dismissAllToast()
          this.configService.presentToast("dataSaved", this.configService.languageJson[this.configService.getLang()]["mediaservice"]["saveFileToDisk"]["dataSavedToStorage"], "", "middle", 2000)
          //if (this.platform.is("ios")) this.doDownloadToDevice(response.fullPath, this.file.documentsDirectory)
        }).catch(err => {
          //console.log("save base64blob - folder exist - fail", err)
          this.configService.dismissAllToast()
          if (err.code == 12) {
            //console.log("save base64blob | create new filename from", _name)
            let nameSplit = _name.split(".")
            let fE = nameSplit.slice(nameSplit.length - 1, nameSplit.length)
            let newName = nameSplit.length == 2 ? nameSplit[0] + "(1)." + fE : nameSplit.slice(0, nameSplit.length - 1).join('') + "(1)." + fE
            this.saveFileToDisk(_blob, newName)
            return this.configService.presentToast("dataSaved", this.configService.languageJson[this.configService.getLang()]["mediaservice"]["saveFileToDisk"]["dataAlreadyExist"], "", "middle", 2000)
          }
          this.configService.presentToast("dataSaved", this.configService.languageJson[this.configService.getLang()]["mediaservice"]["saveFileToDisk"]["failedSavingToDevice"], "", "middle", 2000)
        })
      })
      .catch(err => {
        //console.log("checkFileDir -> ERR/EXIST", err)
        this.file.createDir(this.storageLocation, 'BetterApp', false).then(result => {
          this.file.writeFile(this.storageLocation + 'BetterApp/', _name, _blob).then(response => {
            //console.log("save base64blob success", response)
            //if (this.platform.is("ios")) return this.iosOpenFile(iosStorageLocation, mimeType, _name)
            this.configService.dismissAllToast()
            this.configService.presentToast("dataSaved", this.configService.languageJson[this.configService.getLang()]["mediaservice"]["saveFileToDisk"]["dataSavedToStorage"], "", "middle", 2000)
            if (this.platform.is("ios")) this.doDownloadToDevice(response.fullPath, this.file.documentsDirectory)
          }).catch(err => {
            //console.log("save base64blob - folder NOT exist - fail", err)
            this.configService.dismissAllToast()
            //this.configService.presentToast("dataSaved", this.configService.languageJson[this.configService.getLang()]["mediaservice"]["saveFileToDisk"]["failedSavingToDevice"], "", "middle", 2000)
            if (err.code == 12) {
              let nameSplit = _name.split(".")
              let fE = nameSplit.slice(nameSplit.length - 1, nameSplit.length)
              let newName = nameSplit.length == 2 ? nameSplit[0] + "(1)." + fE : nameSplit.slice(0, nameSplit.length - 1).join('') + "(1)." + fE
              this.saveFileToDisk(_blob, newName)
              return this.configService.presentToast("dataSaved", this.configService.languageJson[this.configService.getLang()]["mediaservice"]["saveFileToDisk"]["dataAlreadyExist"], "", "middle", 2000)
            }
          })
        }).catch(err => console.log("err creating DIR -> ", err))
      });
  }

  checkFileExtension(_fileExtension/*,callback*/): Observable<any> {
    return this.http.get("./../../assets/mimeTypes/db.json").pipe(
      //tap(res => console.log("res /db.json", res)),
      map((res: any) => {
        //console.log("_fileExtension", _fileExtension)
        const mimeJson = res
        let mimeType = ""
        for (var i in mimeJson) {
          if (mimeJson[i].extensions && mimeJson[i].extensions.length != 0) {
            const extensions = mimeJson[i].extensions
            for (var z in extensions) {
              if (extensions[z] == _fileExtension) {
                mimeType = i
                //console.log("mimeType FOUND", mimeType)
                return mimeType
              }
            }
          }
        }
        return ""
      })
    )
  }

  checkMimeTypeForFileExtension(_mimeType/*,callback*/): Observable<any> {
    return this.http.get("./../../assets/mimeTypes/db.json").pipe(
      //tap(res => console.log("checkMimeTypeForFileExtension | res /db.json", res)),
      map((res: any) => {
        //console.log("_mimeType", _mimeType)
        const mimeJson = res
        let extension = ""
        for (var i in mimeJson) {
          if (mimeJson[i].extensions && mimeJson[i].extensions.length != 0) {
            if (i == _mimeType) {
              extension = mimeJson[i].extensions[0]
              console.log("extension FOUND", extension)
              return extension
            }
          }
        }
        return ""
      })
    )
  }

  iosOpenFile(_uri, _type, _name) {
    console.log("iosOpenFile", _uri, _type, _name)
    this.fileOpener.showOpenWithDialog(
      //this.fileOpener.open(
      _uri,
      _type
    ).then((success) => {
      //console.log('file opened successfully');
      this.configService.presentToast("dataSaved", this.configService.languageJson[this.configService.getLang()]["mediaservice"]["saveFileToDisk"]["dataSavedToStorage"], "", "middle", 2000)
    }).catch((err) => {
      console.log('iosOpenFile Error status: ' + err.status + ' - Error message: ' + err.message);
      this.configService.presentToast("dataSaved", this.configService.languageJson[this.configService.getLang()]["mediaservice"]["saveFileToDisk"]["failedSavingToDevice"], "", "middle", 2000)
    }).finally(() => {
      //console.log("file saved or not but now delete file")
      this.file.removeFile(this.storageLocation + 'BetterApp/', _name)
        .then(() => console.log("file deleted ->", _name))
        .catch((err) => console.log("file delete error ->", err))
    })
  }

  checkForImageOrVideo(mimeType: string) {
    const imgTypes = ["image/jpeg", "image/jpg", "image/png"]
    const vidTypes = ["video/mp4", "video/ogg", "video/MOV"]
    for (var i in imgTypes)
      if (imgTypes[i] == mimeType) return "img_"
    for (var o in vidTypes)
      if (vidTypes[o] == mimeType) return "vid_"
    return false
  }

  saveToGallery(_base64, _prefix, _fileExtension) {
    //console.log("saveToGallery | _fileExtension, _prefix", _fileExtension, _prefix)
    if (!_base64 || !_prefix) return console.log("Error - saveToGallery ... no base64 data or prefix", _base64, _prefix)
    this.configService.presentToast("saveToGallery", this.configService.languageJson[this.configService.getLang()]["mediaservice"]["saveToGallery"]["waitUntilSavedToGallery"], "", "middle", 4000)
    let splitData = _base64.split(",")[1]
    splitData = splitData ? splitData : _base64
    let toDecode = splitData
      .split("\u21b5").join('')
      .replace(/[\n\r]/g, '')
      .replace("↵", "")

    const options: Base64ToGalleryOptions = { prefix: _prefix, mediaScanner: true }
    //console.log('hmm - Base64ToGalleryOptions', options, _base64.substring(0, 50) + " | " + _base64.substring(_base64.length - 2, _base64.length), _prefix, _fileExtension)
    this.base64ToGallery.base64ToGallery(toDecode, options).then(
      res => {
        //console.log('Saved image to gallery ', res)
        this.configService.dismissAllToast()
        this.configService.presentToast("dataSaved", this.configService.languageJson[this.configService.getLang()]["mediaservice"]["saveToGallery"]["savedToGallery"], "", "middle", 2000)
      },
      err => {
        //console.log('Error saving image to gallery ', err)
        this.configService.dismissAllToast()
        this.configService.presentToast("dataSaveError", this.configService.languageJson[this.configService.getLang()]["mediaservice"]["saveToGallery"]["noDataSaved"], "", "middle", 2000)
      }
    ).catch((err) => {
      console.log("error | base64ToGallery | err->", err)

    }).finally(() => {
      //console.log("finally | base64ToGallery")
      this.configService.dismissAllToast()
    });
  }

  /*download(b64Data, fileName, mimeType) {
    this.configService.presentToast("downloadToast", this.configService.languageJson[this.configService.getLang()]["mediaservice"]["download"]["waitSaving"], "", "middle", 60000)
    this.storageLocation = this.file.externalRootDirectory
    if (this.platform.is("ios")) this.storageLocation = this.file.documentsDirectory /*+ '/NoCloud/'
    const split = b64Data.split(";base64,")
    //console.log("split.length,", split, split.length, mimeType, b64Data)
    var uri = split.length != 1 ? encodeURI(b64Data) : encodeURI('data:' + mimeType + ';base64,' + b64Data);
    //console.log("uri,", uri)
    const fileTransfer: FileTransferObject = this.fileTransfer.create();
    fileTransfer.download(uri, this.storageLocation + "BetterApp/" + fileName).then((entry) => {
      //console.log('download complete: ' + entry.toURL())
      this.configService.dismissAllToast()
    }, (error) => {
      // handle error
      console.log('download error: ', error)
      this.configService.dismissAllToast()
      this.configService.presentToast("downloadToast", this.configService.languageJson[this.configService.getLang()]["mediaservice"]["download"]["errorWhileSaving"], "", "middle", 20000)
    })
  }*/

  b64toBlob(b64Data, contentType) {
    contentType = contentType || '';
    var sliceSize = 512;
    try {
      var byteCharacters = atob(b64Data)
    } catch (err) {
      //console.log("b64toBlob error @ atob", err)
      b64Data = this.splitBase64(b64Data)
      b64Data = b64Data.replace(/\r\n/g, '')
      var byteCharacters = atob(b64Data)
    }
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  splitBase64(_string) {
    const a = _string.split('base64,')
    let b = ""
    if (a.length == 2)
      return a[1]
    return _string
  }

  doDownloadToDevice(_fileUrl, _downloadPath): void {
    //console.log("doDownloadToDevice", _fileUrl, _downloadPath)
    this.nativeHttp.downloadFile(_fileUrl.url, {}, {}, _downloadPath)
      .finally(() => console.info("finally"))
      .then((_r_) => {
        //console.info("SUCCESS", _r_);
      }, (e) => {
        console.warn("Download error", e);
      });
  }

  deleteDownload(_msgId) {
    window.localStorage.removeItem("currentDownloadMsgId")
    window.localStorage.removeItem("lastSavedChunk")
    window.localStorage.removeItem("maxDownloadChunk-" + _msgId)
    window.localStorage.removeItem("currentDownloadChunk-" + _msgId)
    window.localStorage.removeItem("currentDownloadFriendName-" + _msgId)

    this.configService.storage_remove("download-" + _msgId)

    this.http.post("https://server.betterapp.de/download_mediaChunk_done", { msgId: _msgId })
      .subscribe((response: any) => {
        if (response.err) return console.error("could not remove download from server", response.err)
        console.log("download removed from server", response)
        this.configService.presentToast("oldImagePgP", this.configService.languageJson[this.configService.getLang()]["mediaservice"]["download"]["errorOldPgP"], "", "middle", 2000)
      })
  }

}
