import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { ToastController } from '@ionic/angular';
import { Router } from '@angular/router';
import { NativeRingtones } from '@ionic-native/native-ringtones/ngx';
import { Events } from '@ionic/angular'
import { Platform } from '@ionic/angular'

import * as CryptoJS from 'crypto-js';

import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  /**
    requests    -> holds all request which was not send successfully to try again
    lastAction  -> time last transaction was triggered -> used to prevent sending again in a certain amount of time
    username    -> Is yeah ... the username
    toast       -> Holds the last toast-id
    newUser     -> Is true if user has registered a new account
    friendList  -> Used for holding all friends regardless of accepted or not
  */
  private requests: any
  private lastAction: number
  public username: string
  public newUser: boolean = false
  public settings: any
  public friendList: any
  public currentChatFriend: any

  public hideTabBar: boolean = false

  //unique for every msg
  public msgId: any
  //uniqe generated per device after first new/hard login
  public deviceId: any

  private inOperation: boolean = false
  private operationArray: any = []

  private inOperationLinkPreview: boolean = false
  private operationArrayLinkPreview: any = []

  //holds push key and indicates if a new key is available
  public changeGcmKey: boolean = false
  public gcmKey: any

  // enable or disable the footer tabs
  public tabsVisible: boolean = false

  // holds wifi connection type
  public wifiState: string = ""

  public currentPlatform: any

  public gotNewNotification: boolean = false

  private localHash: any

  private lastSendMsgTime: number = 0

  //android camera restart...
  public goToChat: boolean = false

  //language system
  public languageJson
  public currentLang = "en"

  /**
   * @ignore
   */
  constructor(
    private storage: Storage,
    public toastController: ToastController,
    private router: Router,
    private ringtones: NativeRingtones,
    public events: Events,
    private platform: Platform,
    private http: HttpClient
  ) {
    this.parseLanguageFile()
    this.storage.ready().then((status) => {
      console.log("storage.ready() | status", status)
      this.storage_get("settings").then(data => {
        this.settings = data
        // delete all messages if timer is out of range
        console.log("start del msg with timer")
        this.checkForTime()
      })
      this.getUsername()
      this.currentPlatform = this.platform.platforms()
      console.log("storage_listKeys()", this.storage_listKeys())
    })
    this.requests = []
    this.msgId = window.localStorage.getItem("msgId") ? window.localStorage.getItem("msgId") : 0
    this.generateDeviceId()
  }

  async checkForDbReady() {
    let status = await this.storage.ready()
    //console.log("checkForDbReady | status",status)
    if (status) return true
    else return false
  }

  getUsername() {
    this.storage_get("username").then(data => {
      this.username = data
    })
  }

  generateDeviceId() {
    //console.log("generateDeviceId")
    //console.log("deviceId",window.localStorage.getItem("deviceId"))
    this.deviceId = window.localStorage.getItem("deviceId") ? window.localStorage.getItem("deviceId") : this.generate_token(10)
    window.localStorage.setItem("deviceId", this.deviceId)
    //console.log("generated->",this.deviceId)
    return this.deviceId
  }


  //###########################################################################
  //################              Ionic 4 Storge                ###############
  //###########################################################################


  /**
   * Save a value in persistant location 
   * Big data is allowed
   */
  async storage_save(key: string, value: any, useEncryption: boolean) {
    //check if encryption is active, if so encrypt chat data before using
    if (useEncryption && this.settings.encryptionActive)
      value = await this.encrypt(value)

    // set a key/value
    if (!this.checkForDbReady()) return this.storage_save(key, value, useEncryption)
    this.storage.set(key, value).then((info) => {
      return info
    }).catch((err) => {
      console.warn("storage_save | error", err)
    })
  }
  async storage_saveCallback(key: string, value: any, useEncryption: boolean, callback: any) {
    //check if encryption is active, if so encrypt chat data before using
    if (useEncryption && this.settings.encryptionActive)
      value = await this.encrypt(value)

    // set a key/value
    if (!this.checkForDbReady()) return this.storage_saveCallback(key, value, useEncryption, callback)
    this.storage.set(key, value).then((info) => {
      return callback(info)
    }).catch((err) => {
      console.warn("storage_saveCallback | error", err)
    })
  }

  /**
   * Get a value from persistant location
   */
  storage_get(key: string) {
    // Or to get a key/value pair
    //let value = this.storage.get(key)
    //check if encryption is active, if so decrypt chat data before using
    //if(this.settings && this.settings.encryptionActive) 
    //  return this.decrypt(value)
    //return value
    if (!this.checkForDbReady()) return this.storage_get(key)
    return this.storage.get(key).catch((err) => {
      console.warn("storage_get | error", err)
    })
  }

  /**
   * Get all key names
   */
  async storage_listKeys() {
    return await this.storage.keys()/*.catch((err) => {
      console.warn("storage_get | error", err)
    })*/
  }

  /**
   * remove all key
   */
  storage_remove(key: string) {
    return this.storage.remove(key).catch((err) => {
      console.warn("storage_remove | error", err)
    })
  }

  /**
   * Clear all storage
   */
  storage_length() {
    return this.storage.length().catch((err) => {
      console.warn("storage_length | error", err)
    })
  }

  /**
   * Clear all storage
   */
  storage_clear() {
    return this.storage.clear().catch((err) => {
      console.warn("storage_clear | error", err)
    })
  }


  //###########################################################################
  //################              MessageEncryption             ###############
  //###########################################################################

  //AES 256 is virtually impenetrable using brute-force methods. 
  //While a 56-bit DES key can be cracked in less than a day, 
  //AES would take billions of years to break using current computing technology. 
  //Hackers would be foolish to even attempt this type of attack. Nevertheless, 
  //no encryption system is entirely secure.


  sethash(_hash) {
    this.localHash = _hash
    return true
  }

  checkHash() {
    if (this.localHash)
      return true
    else
      return false
  }

  removeHash() {
    this.localHash = undefined
    return true
  }

  async decrypt(_value) {
    if (!this.localHash) {
      this.events.publish("promtForPin")
      return false
    }
    if (!_value) {
      console.error("AES | decrypt | no value", _value)
      return false
    }
    let originalText
    try {
      const bytes = await CryptoJS.AES.decrypt(_value, this.localHash)
      originalText = await bytes.toString(CryptoJS.enc.Utf8)
    } catch (error) {
      console.error("DECRYPT | error->", error)
      return false
    }
    return originalText
  }

  async decryptBase64(_value) {
    if (!this.localHash) {
      this.events.publish("promtForPin")
      return false
    }
    const bytes = await CryptoJS.AES.decrypt(_value, this.localHash)
    const originalText = await bytes.toString(CryptoJS.enc.Base64)
    return originalText
  }

  async encrypt(_value) {
    if (!this.localHash) {
      this.events.publish("promtForPin")
      return false
    }
    const ciphertext = await CryptoJS.AES.encrypt(_value, this.localHash).toString();
    return ciphertext
  }

  checkPin(_pin, _cb) {
    let abortTimer
    this.storage_get("encryptionTest").then(async (data) => {
      abortTimer = setTimeout((__cb) => {
        return __cb(false)
      }, 1500, _cb);
      const bytes = await CryptoJS.AES.decrypt(data, _pin)
      let originalText
      try {
        originalText = JSON.parse(await bytes.toString(CryptoJS.enc.Utf8))
      } catch (error) {
        originalText = await bytes.toString(CryptoJS.enc.Utf8)
      }
      clearTimeout(abortTimer)
      if (originalText == Number(_pin)) {
        this.sethash(_pin)
        return _cb(true)
      }
      return _cb(false)
    })
  }

  //###########################################################################
  //################              Encryption - CHAT             ###############
  //###########################################################################

  encryptChat(_cb) {
    console.log("encryptChat")
    let finishedCount = 0
    this.storage_get("friendList").then(async friendList => {
      for (var i in friendList) {
        this.getUserMessageToEncrypt(friendList[i].username, () => {
          finishedCount++
          if (finishedCount == friendList.length)
            return _cb()
        })
      }
    })
  }
  getUserMessageToEncrypt(_username, _cb) {
    //console.log("getUserMessageToEncrypt", _username)
    this.storage_get(_username + "-containerCount").then(async (containerCount) => {
      var data = containerCount ? containerCount : 0
      var i = 0
      while (i <= data) {
        this.encryptMsgStorage(_username, i, () => {
          return _cb()
        })
        this.checkImageStorageForEncryption(_username)
        i++
      }
    })
  }
  encryptMsgStorage(_username, msgStorage, _cb) {
    //console.log("encryptMsgStorage", _username, msgStorage)
    this.storage_get(_username + '-msgStorage-' + msgStorage).then(async (_data) => {
      if (!_data) return _cb()
      const encrypted = await this.encrypt(JSON.stringify(_data))
      this.storage_save(_username + '-msgStorage-' + msgStorage, encrypted, false)
      return _cb()
    })
  }
  checkImageStorageForEncryption(_username) {
    //console.log("checkImageStorage", _username)
    let imgStorage = window.localStorage.getItem(_username + "-img") ? JSON.parse(window.localStorage.getItem(_username + "-img")) : false
    let fileStorage = window.localStorage.getItem(_username + "-file") ? JSON.parse(window.localStorage.getItem(_username + "-file")) : false
    let videoStorage = window.localStorage.getItem(_username + "-video") ? JSON.parse(window.localStorage.getItem(_username + "-video")) : false
    if (imgStorage)
      for (var i in imgStorage)
        this.encryptImageStorage("img", imgStorage[i].msgId, imgStorage[i].fileName)
    if (fileStorage)
      for (var i in fileStorage)
        this.encryptImageStorage("file", fileStorage[i].msgId, fileStorage[i].fileName)
    if (videoStorage)
      for (var i in videoStorage)
        this.encryptImageStorage("video", videoStorage[i].msgId, videoStorage[i].fileName)
  }
  encryptImageStorage(_type, _msgId, _fileName) {
    //console.log("checkImageStorage", _type, _msgId, _fileName)
    this.storage_get(_type + "#" + _fileName + "#" + _msgId).then(async (_data) => {
      if (!_data) return
      this.storage_save(_type + "#" + _fileName + "#" + _msgId, _data, true)
    })
  }

  //###########################################################################
  //################              Decryption - CHAT             ###############
  //###########################################################################

  decryptChat(_cb) {
    //console.log("decryptChat")
    let finishedCount = 0
    this.storage_get("friendList").then(async friendList => {
      for (var i in friendList) {
        this.getUserMessageToDecrypt(friendList[i].username, () => {
          finishedCount++
          if (finishedCount == friendList.length)
            return _cb()
        })
      }
    })
  }
  getUserMessageToDecrypt(_username, _cb) {
    //console.log("getUserMessageToDecrypt", _username)
    this.storage_get(_username + "-containerCount").then(async (containerCount) => {
      var data = containerCount ? containerCount : 0
      var i = 0
      while (i <= data) {
        this.decryptMsgStorage(_username, i, () => {
          return _cb()
        })
        this.checkImageStorageForDecryption(_username)
        i++
      }
    })
  }
  decryptMsgStorage(_username, msgStorage, _cb) {
    //console.log("decryptMsgStorage", _username, msgStorage)
    this.storage_get(_username + '-msgStorage-' + msgStorage).then((_data) => {
      if (!_data) return _cb()
      this.decrypt(_data).then((decrypted) => {
        if (!decrypted) return _cb()
        decrypted = JSON.parse(decrypted)
        this.storage_save(_username + '-msgStorage-' + msgStorage, decrypted, false)
        return _cb()
      })
    })
  }

  checkImageStorageForDecryption(_username) {
    //console.log("checkImageStorage", _username)
    let imgStorage = window.localStorage.getItem(_username + "-img") ? JSON.parse(window.localStorage.getItem(_username + "-img")) : false
    let fileStorage = window.localStorage.getItem(_username + "-file") ? JSON.parse(window.localStorage.getItem(_username + "-file")) : false
    let videoStorage = window.localStorage.getItem(_username + "-video") ? JSON.parse(window.localStorage.getItem(_username + "-video")) : false
    if (imgStorage)
      for (var i in imgStorage)
        this.encryptImageStorage("img", imgStorage[i].msgId, imgStorage[i].fileName)
    if (fileStorage)
      for (var i in fileStorage)
        this.encryptImageStorage("file", fileStorage[i].msgId, fileStorage[i].fileName)
    if (videoStorage)
      for (var i in videoStorage)
        this.encryptImageStorage("video", videoStorage[i].msgId, videoStorage[i].fileName)
  }
  decryptImageStorage(_type, _msgId, _fileName) {
    //console.log("checkImageStorage", _type, _msgId, _fileName)
    this.storage_get(_type + "#" + _fileName + "#" + _msgId).then(async (_data) => {
      if (!_data) return
      this.decrypt(_data).then((decrypted) => {
        this.storage_save(_type + "#" + _fileName + "#" + _msgId, decrypted, false)
      })
    })
  }

  //###########################################################################
  //################              Ionic 4 Toast                ###############
  //###########################################################################


  private toast: any = []

  async presentToast(
    toastId: string,
    msg: string,
    header: string,
    position: "top" | "bottom" | "middle",
    time: any
  ) {
    var id = this.toast[toastId] = await this.toastController.create({
      header: header,
      message: msg,
      position: position,
      duration: time
    })
    this.toast[toastId].present()
  }


  /**
   * 
   * @param msg 
   * @param header 
   * @param position 
   * @param buttons 
   * 
   * just add the buttons you need because close is always present 
   * or add {} for only abort button
   *  [
        {                 
          side: 'start',
          icon: 'star',
          text: 'Favorite',
          handler: () => {
            console.log('Favorite clicked');
          }
        }, {
          text: 'Done',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        }
      ]
   * @param time 
   */

  async presentToastWithOptions(
    toastId: string,
    msg: string,
    header: string,
    position: "top" | "bottom" | "middle",
    button: object
  ) {
    var buttons = []
    if (button != []) buttons.concat(button)
    buttons.push({
      text: 'X',
      role: 'cancel',
      handler: () => {
        //console.log('Cancel clicked');
      }
    })
    var id = this.toast[toastId] = await this.toastController.create({
      header: header,
      message: msg,
      position: position,
      buttons: buttons
    })
    this.toast[toastId].present();
  }

  async presentToastWithGoTo(
    toastId: string,
    msg: string,
    header: string,
    position: "top" | "bottom" | "middle",
    time: number
  ) {
    var id = this.toast[toastId] = await this.toastController.create({
      header: header,
      message: msg,
      position: position,
      duration: time
    })
    this.toast[toastId].present();
  }

  async goToChatToast(
    toastId: string,
    username: string,
    msg: string,
    header: string,
    position: "top" | "bottom" | "middle"
  ) {
    var buttons = []
    buttons.push({
      text: 'Open',
      role: 'submit',
      handler: () => {
        //console.log("button handler")
        this.storage_saveCallback("chatUser", username, false, (info) => {
          this.goTo("chat")
        })
      }
    })
    var id = this.toast[toastId] = await this.toastController.create({
      header: header,
      message: msg,
      position: position,
      buttons: buttons
    })
    this.toast[toastId].present();
    setTimeout((_toastId) => {
      this.dismissToast(_toastId)
    }, 2000, toastId)
  }

  dismissToast(toastId) {
    try {
      this.toast[toastId].dismiss();
    } catch (err) { console.log("Can´t dismissToast() with id '" + toastId + "', toast not available!") }

    return true
  }

  dismissAllToast() {
    try {
      for (var i in this.toast) this.toast[i].dismiss();
    } catch (err) { console.log("Can´t dismissToast(), no toast available!") }

    return true
  }

  //###########################################################################
  //################              Save User-Data                ###############
  //###########################################################################
  private lastSavedMsgId: any = 0

  userData_save(
    _data: any,
    _msgId: any,
    _user: string,
    _type: ("text" | "img" | "file" | "video" | "photo"),
    _fileName: string,
    _timeStamp,
    _wasSend: boolean,
    _hasLink: any,
    _cb: any
  ) {
    console.log("userData_save", _data)
    if (this.inOperation) {
      //console.log("userData_save | STOP is in operation ... add to opArray - msgId->" + _msgId, this.inOperation)
      return this.operationArray.push({
        action: "save",
        data: _data,
        msgId: _msgId,
        user: _user,
        type: _type,
        fileName: _fileName,
        timeStamp: _timeStamp,
        wasSend: _wasSend,
        hasLink: _hasLink,
      })
    }
    //console.log("userData_save | OK no operation is running ... now save - msgId->"+_msgId,this.inOperation)

    this.inOperation = true

    const dataObj = {
      from: _user,
      data: _data,
      msgId: _msgId,
      status: "hourglass",
      fileStatus: "none",
      type: _type,
      fileName: _fileName,
      timeStamp: this.dateConverter(_timeStamp),
      wasSend: _wasSend,
      hasLink: _hasLink,
    }

    this.storage_get(_user + "-containerCount").then((containerCount) => {
      console.log("dataObj | String(containerCount)", dataObj, String(containerCount))
      if (!String(containerCount) || String(containerCount) == "null" || containerCount == null ) {
        containerCount = 0
        this.storage_save(_user + "-containerCount", 0, false)
        return this.storage_saveCallback(_user + "-msgStorage-" + containerCount, JSON.stringify([dataObj]), true, (info) => {
          console.log("userData_save | storage_saveCallback | OK", info)
          this.check_operationArray()
          return _cb()
        })
      }

      this.storage_get(_user + "-msgStorage-" + containerCount).then((data) => {
        if (!data) return this.storage_saveCallback(_user + "-msgStorage-" + containerCount, JSON.stringify([dataObj]), true, (info) => {
          console.log("userData_save | storage_saveCallback | OK", info)
          this.check_operationArray()
          return _cb()
        })
        //check if encryption is active, if so decrypt chat data before using
        if (data && this.settings.encryptionActive)
          this.decrypt(data).then((decrypted) => {
            this.userData_saveData(dataObj, decrypted, _user, containerCount, _cb)
          })
        else
          this.userData_saveData(dataObj, data, _user, containerCount, _cb)
      })
    })
  }

  userData_saveData(_dataObj, _data, _user, _containerCount, __cb) {
    if (!_data) return __cb(false)

    console.log("######---> typeof _data <---####", typeof _data)
    if (typeof _data === 'string')
      _data = JSON.parse(_data)

    let data = _data ? _data : []
    console.log("######---> DATA <---####", data, _data)
    //check if msgId is already available in this userMsgBlock
    if (data && data.length != 0 && data.find((element: any) => {
      element.msgId == _dataObj.msgId
    })) {
      this.check_operationArray()
      return __cb()
    }
    this.lastSendMsgTime = Date.now()

    if (data.length == 19) {
      _containerCount = _containerCount + 1
      this.storage_save(_user + "-containerCount", _containerCount, false)
      this.storage_saveCallback(_user + "-msgStorage-" + _containerCount, JSON.stringify([_dataObj]), true, (info) => {
        this.check_operationArray()
        return __cb()
      })
    } else {
      data.push(_dataObj)
      this.storage_saveCallback(_user + "-msgStorage-" + _containerCount, JSON.stringify(data), true, (info) => {
        //console.log("userData_update storage_saveCallback",this.operationArray)
        this.check_operationArray()
        return __cb()
      })
    }
  }

  userData_update(_msgId: any, _user: string, _keyToChange: string, _valueToChange: string) {
    if (!_msgId || !_user || !_keyToChange || !_valueToChange)
      return console.warn("userData_update | wrong value input", _msgId, _user, _keyToChange, _valueToChange)
    if (!this.inOperation && this.operationArray.length == 0)
      return this.userData_updateAction(_msgId, _user, _keyToChange, _valueToChange)
    //console.log("userData_update | Ok, nothing is running proceed with update", this.inOperation, this.operationArray)
    //console.log("userData_update | STOP... update is running or opArray is not empty", this.inOperation, this.operationArray)
    return this.operationArray.push({ action: "update", msgId: _msgId, user: _user, keyToChange: _keyToChange, valueToChange: _valueToChange })
    //this.check_operationArray()
  }

  userData_updateAction(_msgId: any, _user: string, _keyToChange: string, _valueToChange: any) {
    //console.log("userData_updateAction | storage_get data->", _msgId, _user, _keyToChange, _valueToChange)
    if (!_msgId || !_user || !_keyToChange || !_valueToChange) return console.log("ABORT userData_updateAction - empty value detected...")
    this.inOperation = true
    this.storage_get(_user + "-containerCount").then((count) => {
      var containerCount = Number(count)
      if (containerCount == undefined) return this.check_operationArray()
      this.userData_updateAction_checkForData(_user, _msgId, _keyToChange, _valueToChange, containerCount)
    })
  }

  userData_updateAction_checkForData(_user, _msgId, _keyToChange, _valueToChange, _containerCount) {
    this.storage_get(_user + "-msgStorage-" + _containerCount).then((data) => {
      if (!data) return
      //check if encryption is active, if so decrypt chat data before using
      if (this.settings.encryptionActive)
        this.decrypt(data).then((decrypted) => {
          this.userData_updateAction_saveData(decrypted, _msgId, _user, _keyToChange, _valueToChange, _containerCount)
        })
      else
        this.userData_updateAction_saveData(data, _msgId, _user, _keyToChange, _valueToChange, _containerCount)
    })
  }

  userData_updateAction_saveData(_data, _msgId, __user, __keyToChange, __valueToChange, __containerCount) {
    if (typeof _data === 'string')
      _data = JSON.parse(_data)
    //console.log("userData_updateAction | storage_get data->", data)
    for (var i in _data) {
      if (_data[i].msgId == _msgId) {
        //console.log("userData_updateAction | update user chat data in DB", data[i].msgId, _msgId)
        _data[i][__keyToChange] = __valueToChange

        return this.storage_saveCallback(__user + "-msgStorage-" + __containerCount, JSON.stringify(_data), true, (info) => {
          //console.log("userData_update storage_saveCallback",this.operationArray)
          this.check_operationArray()
        })
      }
    }

    //if container_count is not 0 check prev until you reach 0
    if (__containerCount != 0) {
      __containerCount--
      return this.userData_updateAction_checkForData(__user, _msgId, __keyToChange, __valueToChange, __containerCount)
    }
    //console.log("userData_updateAction | NO MATCH FOUND IN USER CHAT-DB", _user + "-msgStorage-" + containerCount, _msgId, data)

    //if OP-Array is not empty and the current msgId was not found in OP-Array, push it to the OP-Array
    if (this.operationArray.length != 0 && !this.operationArray.find(element => element.msgId == _msgId))
      this.operationArray.push({ action: "update", msgId: _msgId, user: __user, keyToChange: __keyToChange, valueToChange: __valueToChange })

    this.check_operationArray()
  }

  check_operationArray() {
    //console.log("check_operationArray | this.operationArray.length->", this.operationArray.length, this.operationArray, this.inOperation)
    this.inOperation = false
    if (this.operationArray.length == 0) return

    if (this.operationArray[0].action == "update")
      this.userData_updateAction(
        this.operationArray[0].msgId,
        this.operationArray[0].user,
        this.operationArray[0].keyToChange,
        this.operationArray[0].valueToChange
      )
    else if (this.operationArray[0].action == "save")
      this.userData_save(
        this.operationArray[0].data,
        this.operationArray[0].msgId,
        this.operationArray[0].user,
        this.operationArray[0].type,
        this.operationArray[0].fileName,
        this.operationArray[0].timeStamp,
        this.operationArray[0].wasSend,
        this.operationArray[0].hasLink,
        () => {
          //console.log("userData saved")
        }
      )

    this.operationArray.shift()
    //console.log("+++++++END++++++ check_operationArray | this.operationArray.length->", this.operationArray.length, this.operationArray)
  }

  userData_updateData(_msgId: any, _data: any, _user: string) {
    if (!this.inOperationLinkPreview) {
      this.userData_updateDataAction(_msgId, _data, _user)
    } else {
      this.operationArrayLinkPreview.push({ msgId: _msgId, data: _data, user: _user })
    }
  }

  userData_updateDataAction(_msgId: any, _data: string, _user: string) {
    this.inOperationLinkPreview = true
    this.storage_get(_user + "-containerCount").then((count) => {
      var containerCount = Number(count)
      if (containerCount == undefined) return []
      this.storage_get(_user + "-msgStorage-" + containerCount).then(async (data) => {
        if (!data) return
        //check if encryption is active, if so decrypt chat data before using
        if (this.settings.encryptionActive)
          this.decrypt(data).then((decrypted) => {
            this.userData_updateDataAction_saveData(decrypted, _user, _msgId, containerCount)
          })
        else
          this.userData_updateDataAction_saveData(data, _user, _msgId, containerCount)
      })
    })
  }

  userData_updateDataAction_saveData(_data, __user, __msgId, _containerCount) {
    if (!_data) return
    if (typeof _data === 'string')
      _data = JSON.parse(_data)

    for (var i in _data) {
      if (_data[i].msgId == __msgId) {
        //console.log("update user in DB", data[i].msgId, _msgId)
        _data[i] = _data
      }
    }
    this.storage_saveCallback(__user + "-msgStorage-" + _containerCount, JSON.stringify(_data), true, (info) => {
      if (this.operationArrayLinkPreview.length != 0) {
        this.userData_updateDataAction(this.operationArrayLinkPreview[0].msgId, this.operationArrayLinkPreview[0].data, this.operationArrayLinkPreview[0].user)
        this.operationArrayLinkPreview.splice(0, 1)
      } else this.inOperationLinkPreview = false
    })
  }

  userData_get(_user: string, callback: any) {
    this.storage_get(_user + "-containerCount").then((count) => {
      //console.log(_user + "-containerCount", count)
      var containerCount = Number(count)
      if (containerCount == null) {
        return callback(null)
      }
      this.storage_get(_user + "-msgStorage-" + containerCount).then((data) => {
        if (!data) return callback(null)
        console.log("this.settings.encryptionActive", this.settings.encryptionActive)
        //check if encryption is active, if so decrypt chat data before using
        if (this.settings.encryptionActive) {
          this.decrypt(data).then((_data) => {
            console.log(_user + " decrypt  ", _data)
            if (!_data) return callback(null)

            if (typeof _data === 'string')
              _data = JSON.parse(_data)

            return callback(_data)
          })
        } else {
          try {
            if (typeof data === 'string')
              data = JSON.parse(data)
            return callback(data)
          } catch (error) {
            console.log("JSON.parse() ERROR -> UserChat", error)
            return callback(null)
          }
        }
      })
    })
  }

  userData_getFrom(_user: string, _containerCount: number, callback: any) {
    this.storage_get(_user + "-containerCount").then((count) => {
      var containerCount = Number(count) - _containerCount
      if (!containerCount) return []
      this.storage_get(_user + "-msgStorage-" + _containerCount).then((data) => {
        if (!data) return callback(null)
        //check if encryption is active, if so decrypt chat data before using
        if (this.settings.encryptionActive)
          return this.decrypt(data).then((_data) => {
            if (!_data) return callback(null)
            if (typeof data === 'string')
              _data = JSON.parse(_data)
            return callback(_data)
          })
        if (typeof data === 'string')
          data = JSON.parse(data)
        return callback(data)
      })
    })
  }


  //###########################################################################
  //################              Read/UnRead msg                ###############
  //###########################################################################

  setWasRead(_friendName) {
    //console.log("setWasRead", _friendName)
    let msgReadObject = window.localStorage.getItem("msgRead") ? JSON.parse(window.localStorage.getItem("msgRead")) : {}
    if (msgReadObject[_friendName])
      msgReadObject[_friendName] = msgReadObject[_friendName] + 1
    else
      msgReadObject[_friendName] = 1
    //console.log("setWasRead end", msgReadObject)
    window.localStorage.setItem("msgRead", JSON.stringify(msgReadObject))
    return true
  }

  deleteWasRead(_friendName) {
    let msgReadObject = window.localStorage.getItem("msgRead") ? JSON.parse(window.localStorage.getItem("msgRead")) : {}
    //console.log("deleteWasRead", _friendName,msgReadObject)
    if (!msgReadObject[_friendName]) return false
    delete msgReadObject[_friendName]
    window.localStorage.setItem("msgRead", JSON.stringify(msgReadObject))
    //console.log("deleteWasRead end", _friendName,msgReadObject)
  }

  getWasRead(_friendName) {
    //console.log("getWasRead", _friendName)
    let msgReadObject = window.localStorage.getItem("msgRead") ? JSON.parse(window.localStorage.getItem("msgRead")) : false
    if (!msgReadObject) return false
    if (!msgReadObject[_friendName]) return false
    return msgReadObject[_friendName]
  }

  //###########################################################################
  //################              Ionic 4 RINGTONES                ###############
  //###########################################################################

  ringtone_play() {
    this.ringtones.playRingtone(this.settings.sound.file).catch(err => {
      var audio = new Audio(this.settings.sound.file);
      audio.play();
    })
  }

  ringtone_stop(_uri) {
    this.ringtones.stopRingtone(_uri)
  }

  ringtone_save(_name, _uri) {
    this.settings.sound.file = _uri
    this.settings.sound.sound = _name

    this.storage_save("settings", this.settings, false)
  }


  //###########################################################################
  //################              Create Msg-ID                ###############
  //###########################################################################

  createMsgId() {
    var msgId = this.hashCode(this.username + "/" + this.msgId)
    this.msgId = Number(this.msgId) + 1
    window.localStorage.setItem("msgId", String(this.msgId))
    return msgId
  }

  hashCode(_string) {
    var hash = 0, i, chr;
    if (_string.length === 0) return hash;
    for (i = 0; i < _string.length; i++) {
      chr = _string.charCodeAt(i);
      hash = ((hash << 5) - hash) + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  };
  generate_token(length) {
    //edit the token allowed characters
    var a = "1234567890".split("");
    var b = [];
    for (var i = 0; i < length; i++) {
      var j = (Math.random() * (a.length - 1)).toFixed(0);
      b[i] = a[j];
    }
    return b.join("");
  }

  //########################################### GOTO #########################################
  goTo(siteToNavigate) {
    //console.log("siteToNavigate", siteToNavigate);
    this.router.navigateByUrl("/" + siteToNavigate);
  }
  //########################################### END GOTO #########################################


  /**
   * Check for time and delete all msg if timer is reached
   */

  checkForTime() {
    console.log("checkForTime start", this.settings)
    if (!this.settings || !this.settings.msg) return console.log("checkForTime | no settings obj")
    if (this.settings.msg.time == 0) {
      console.warn("checkForTime | setings msg time is 0")
      this.settings.msg.time = Date.now() + this.settings.msg.deleteTime
      this.events.publish('saveSettings', { command: "", msg: "New delete time set!" })
      return
    }
    if (this.settings.msg.time <= Date.now()) {
      console.log("timer run out ... delete all msg")
      this.deleteAllMsg()
      this.settings.msg.time = Date.now() + this.settings.msg.deleteTime
      this.events.publish('saveSettings', { command: "", msg: "New delete time set!" })
      if (this.currentChatFriend != "") this.events.publish("deleteAllChatMsg")
    } else {
      console.log("timer not run out")
    }
  }

  removeChatData(_username, _showMsg) {
    this.storage_get(_username + "-containerCount").then(async (containerCount) => {
      var data = containerCount ? containerCount : 0
      var i = 0
      while (i <= data) {
        await this.storage_remove(_username + '-msgStorage-' + i)
        i++
      }

      this.deleteAllImgFromFriend(_username)
      this.deleteAllFilesFromFriend(_username, "img")
      this.deleteAllFilesFromFriend(_username, "video")
      this.deleteAllFilesFromFriend(_username, "file")

      this.storage_remove(_username + "-containerCount")
      if (_showMsg)
        this.presentToast("removeChat", this.languageJson[this.settings.lang]["configservice"]["removeChatData"]["err11"] +
          _username +
          this.languageJson[this.settings.lang]["configservice"]["removeChatData"]["err12"], "", "middle", 2000)
    })
  }


  /**
    delete all messages from storage
  */
  deleteAllMsg() {
    this.storage_get("friendList").then(async friendList => {

      let checkForDelete = []
      for (var i in friendList) {
        let notReadMsgCount = this.getWasRead(friendList[i].username)
        var containerCount = 0
        let canDelete = true
        await this.storage_get(friendList[i].username + "-containerCount").then(data => {
          containerCount = (data ? data : 0)
        })

        console.log("deleteAllMsg | friendList[i].username,containerCount", friendList[i].username, containerCount)
        //if not read msg are present for this user
        //save the not read msg as new after deleting everything else
        if (notReadMsgCount)
          await this.storage_get(friendList[i].username + "-msgStorage-" + containerCount).then(async (data) => {
            console.log("deleteAllMsg | storage_get msgstorage", friendList[i].username, data)
            if (!data) return
            let newData
            try {
              if (this.settings.encryptionActive)
                data = JSON.parse(await this.decrypt(data))
              else
                data = JSON.parse(data)

              //get only the not read msg
              if (data.length != 0) newData = data.slice(data.length - notReadMsgCount, data.length)
            } catch (error) {
              console.log("deleteAllMsg | storage_get msgstorage ERROR", friendList[i].username, data)
              checkForDelete.push(friendList[i].username)
              this.deleteAllAndSave(friendList[i].username, false, false)
              return
            }

            this.deleteAllAndSave(friendList[i].username, false, newData)

            //delete all msg
            /*this.deleteAll(containerCount, friendList[i].username, () => {
              //save the not read msg again
              this.storage_saveCallback(friendList[i].username + "-msgStorage-0", newData, true, (info) => {
                //console.log(" ok saved ->",info)
              })
            })*/

          })
        else {
          console.log("deleteAllMsg | no unread msg found")
          checkForDelete.push(friendList[i].username)
          //this.deleteAll(containerCount, friendList[i].username, () => { })
          this.removeChatData(friendList[i].username, false)
        }
      }
      //this.checkIfAllMsgAreDeleted(checkForDelete)
      this.presentToast("msgDeleted", this.languageJson[this.settings.lang]["configservice"]["deleteAllMsg"], "", "top", 2000)
      this.events.publish("refreshChat")
    })

    let currentDownload = window.localStorage.getItem("currentDownloadMsgId")
    if (currentDownload) {
      window.localStorage.removeItem("currentDownloadChunk-" + currentDownload)
      window.localStorage.removeItem("currentDownloadFriendName-" + currentDownload)
      window.localStorage.removeItem("currentDownloadMsgId")
    }
  }
  checkIfAllMsgAreDeleted(checkForDelete) {
    for (var i in checkForDelete) {
      this.removeChatData(checkForDelete[i], false)
    }
  }

  deleteAllAndSave(_username, _showMsg, _newData) {

    this.storage_get(_username + "-containerCount").then(async (containerCount) => {
      var data = containerCount ? containerCount : 0
      var i = 0
      while (i <= data) {
        await this.storage_remove(_username + '-msgStorage-' + i)
        i++
      }

      this.deleteAllImgFromFriend(_username)
      this.deleteAllFilesFromFriend(_username, "img")
      this.deleteAllFilesFromFriend(_username, "video")
      this.deleteAllFilesFromFriend(_username, "file")

      if (containerCount) this.storage_remove(_username + "-containerCount")
      if (_showMsg)
        this.presentToast("removeChat", this.languageJson[this.settings.lang]["configservice"]["removeChatData"]["err11"] +
          _username +
          this.languageJson[this.settings.lang]["configservice"]["removeChatData"]["err12"], "", "middle", 2000)

      if (_newData)
        this.storage_saveCallback(_username + "-msgStorage-0", _newData, true, (info) => {
          //console.log(" ok saved ->",info)
        })
    })
  }

  /**
    delete all images from storage
  */
  async deleteAllImgFromFriend(_friendName) {
    let imageStorage: any = window.localStorage.getItem(_friendName + "-imageStorage")
    if (!imageStorage) return
    imageStorage = JSON.parse(imageStorage)
    for (var i = 0; i < imageStorage.length; i++) {
      await this.storage_remove("photo#" + imageStorage[i])
    }
    window.localStorage.removeItem(_friendName + "-imageStorage")
  }
  /**
    delete all files from storage
  */
  async deleteAllFilesFromFriend(_friendName, _type) {
    let fileStorage: any = window.localStorage.getItem(_friendName + "-" + _type)
    if (!fileStorage) return
    fileStorage = JSON.parse(fileStorage)
    for (var i = 0; i < fileStorage.length; i++) {
      await this.storage_remove(_type + "#" + fileStorage[i].fileName + '#' + fileStorage[i].msgId)
    }
    window.localStorage.removeItem(_friendName + "-" + _type)
  }





  //###########################################################################
  //################              language system               ###############
  //###########################################################################

  parseLanguageFile() {
    this.http.get('assets/language.json').subscribe(res => {
      //console.log('parseLanguageFile',res);
      this.languageJson = res
    },
      (err) => {
        console.log('failed loading json data');
      });
  }

  getLang() {
    return this.settings.lang
  }

  //###########################################################################
  //################             end language system                ###########
  //###########################################################################


  checkUserPubPgp(_friendName) {
    //console.log("checkUserPubPgp",_friendName)
    this.storage_get("friendList").then((res) => {
      for (var i in res)
        if (res[i].username == _friendName) {
          console.log("user found ... now send check as md5", _friendName, this.username, CryptoJS.MD5(res[i].pubKey).toString())
          this.http.post("https://server.betterapp.de/checkForPupPgp", { username: this.username, friendName: _friendName, keyHash: CryptoJS.MD5(res[i].pubKey).toString() }).subscribe(
            res => {
              //console.log("check for PUB-PGP -> success",res)
            },
            err => {
              console.error("check for PUB-PGP ERR->", err)
            }
          )
          break
        }
    })

  }

  timeConverter(_time) {
    var time: any
    if (_time == 3600000) time = 1 + " Hour(s)"
    else if (_time == 21600000) time = 6 + " Hour(s)"
    else if (_time == 43200000) time = 12 + " Hour(s)"
    else time = (_time / 86400000) + " Day(s)"
    //console.log("TIME->", _time, time)
    return time;
  }

  dateConverter(UNIX_timestamp) {
    //console.log("UNIX_timestamp",UNIX_timestamp,Date.now())
    //var a = new Date(UNIX_timestamp * 1000);
    var a = new Date(UNIX_timestamp);
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date: any = a.getDate();
    if (date <= 9) date = "0" + String(date)
    var hour: any = a.getHours();
    if (hour <= 9) hour = "0" + String(hour)
    var min: any = a.getMinutes();
    if (min <= 9) min = "0" + String(min)
    var sec: any = a.getSeconds();
    if (sec <= 9) sec = "0" + String(sec)
    var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
    return time;
  }

  /*startTimer(duration: number, display) {
    let timer: any = duration, minutes, seconds;
    setInterval(function () {
      minutes = parseInt(timer / 60, 10);
      seconds = parseInt(timer % 60, 10);
 
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
 
      display.textContent = minutes + ":" + seconds;
 
      if (--timer < 0) {
        timer = duration;
      }
    }, 1000);
  }*/
}
