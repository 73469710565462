import { ErrorHandler, Injectable } from '@angular/core';
import { Events, Platform } from '@ionic/angular'
import { ConfigService } from './config.service'
import { HttpClient } from '@angular/common/http'

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private platform: Platform
  ) {
    super();
  }

  handleError(error: any): void {
    if (error.message == "ViewDestroyedError: Attempt to use a destroyed view: detectChanges") return
    console.log("got error",{err:error})
    var sendData = {
      err: {
        msg: error.message,
        stack: error.stack
      },
      username: this.configService.username ? this.configService.username : "noUser"
    }
    if (!this.platform.is("desktop"))
      this.http.post('https://server.betterapp.de/saveError', sendData)
        .subscribe(
          res => {
            if (!res) console.log("Error NOT saved!")
            console.log("Error saved!")
          }
          , err => {
            console.error("request ERR->", err)
          }
        )
    super.handleError(error);
  }
}