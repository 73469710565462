import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
//storage
import { IonicStorageModule } from '@ionic/storage';
//http / ajax
import { HttpClientModule } from '@angular/common/http';
import { NativeRingtones } from '@ionic-native/native-ringtones/ngx';
import { Clipboard } from '@ionic-native/clipboard/ngx'
import { Keyboard } from '@ionic-native/keyboard/ngx'
import { Push } from '@ionic-native/push/ngx';
import { BackgroundMode } from '@ionic-native/background-mode/ngx'
import { LocalNotifications } from '@ionic-native/local-notifications/ngx'
import { DatePicker } from '@ionic-native/date-picker/ngx';

import { Crop } from '@ionic-native/crop/ngx'
import { Network } from '@ionic-native/network/ngx';

import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx'
import { Badge } from '@ionic-native/badge/ngx'

import { MediaCapture } from '@ionic-native/media-capture/ngx';
import { Base64 } from '@ionic-native/base64/ngx';
import { Camera } from '@ionic-native/camera/ngx';

import { ImagePicker } from '@ionic-native/image-picker/ngx';
import { File } from '@ionic-native/file/ngx';
import { FileChooser } from '@ionic-native/file-chooser/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { IOSFilePicker } from '@ionic-native/file-picker/ngx'; // IOS
import { Base64ToGallery } from '@ionic-native/base64-to-gallery/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';

import {HTTP} from "@ionic-native/http/ngx";

//import { IonicGestureConfig } from './utils/IonicGestureConfig'
//import { AdMobFree, AdMobFreeBannerConfig } from '@ionic-native/admob-free/ngx'; 

import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

import { ErrorHandler } from '@angular/core';
import { GlobalErrorHandler } from './services/handleError';
import { WebView } from '@ionic-native/ionic-webview/ngx';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule, 
    IonicModule.forRoot(), 
    AppRoutingModule,
    IonicStorageModule.forRoot({
      name: '__BetterAppDB',
      driverOrder: [  'indexeddb', 'websql',/* 'sqlite',*/ ]
    }),  
    HttpClientModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, 
    BackgroundMode,
    NativeRingtones,
    Clipboard,
    Keyboard,
    Push,
    LocalNotifications,
    DatePicker,
    Network,
    Crop,
    ScreenOrientation,
    Badge,
    MediaCapture, 
    Base64,
    Camera,
    ImagePicker,
    File,
    FileChooser,
    FileOpener,
    IOSFilePicker,
    Base64ToGallery,
    FilePath,
    //AdMobFree,
    InAppBrowser,
    HTTP,
    WebView,
    {provide: ErrorHandler, useClass: GlobalErrorHandler}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
