import { Injectable } from '@angular/core'
import { Events } from '@ionic/angular'
import { Observable, of, throwError } from 'rxjs'
import { forkJoin, Subscription } from 'rxjs'
import { catchError, tap, map } from 'rxjs/operators'
import { ConfigService } from './config.service'
import { MediaService } from './media.service'
import { HttpService } from './http.service'
import { FriendListService } from './friend-list.service'
import { PgpService } from './pgp.service'
import { Base64 } from 'js-base64'
import { BackgroundMode } from '@ionic-native/background-mode/ngx'
import { LocalNotifications } from '@ionic-native/local-notifications/ngx'
import { Badge } from '@ionic-native/badge/ngx'
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {

  private subscibtion: Subscription
  public updateInterval: any
  private msgTimer: number = 0
  private msgCounter: number = 1
  private msgRecievedCounter: number = 1
  private hasSarted: boolean = false

  private holdMsgToProcess: any = []
  private currentProcessInt: number = 0
  private checkForFiles: boolean = false
  private wasPlayed: boolean = false
  private language

  constructor(
    private configService: ConfigService,
    private request: HttpService,
    private list: FriendListService,
    private pgp: PgpService,
    public events: Events,
    private backgroundMode: BackgroundMode,
    private localNotifications: LocalNotifications,
    private mediaService: MediaService,
    private domSanitizer: DomSanitizer
  ) {
  }


  ngOnInit() {
    this.getLang()
  }

  getLang() {
    //console.log("updateService getLang()", this.language

    this.language = this.configService.languageJson[this.configService.settings.lang]["updateService"]
    if (!this.language) this.getLang()
  }

  start(_interval: "low" | "medium" | "high") {
    this.getLang()
    //console.log("start updateService with interval: " + _interval)
    if (this.hasSarted) return console.log("error updateService already running!")
    this.configService.storage_get("username").then((data: string) => {
      if (data) {
        this.hasSarted = true
        this.updateInterval = setInterval(() => {
          //console.log("checkUpdates interval")
          this.checkUpdates()
          this.mediaService.resumeDownload()
        }, _interval == "low" ? 10000 : _interval == "medium" ? 2000 : 2000)
      } else console.log("No username set! Abort updateService")
    })
  }

  stop() {
    console.log("checkUpdates stop")
    clearInterval(this.updateInterval)
    this.hasSarted = false
  }

  //###########################################################################
  //################              CHECK 4 UPDATES                ###############
  //###########################################################################

  checkUpdates() {
    if (!this.configService.username) return console.log("abort update request-> no username set", this.configService.username)
    this.subscibtion = this.request.updates().subscribe((data: any) => {
      if (data.reason.length != 0) console.log("check4Updates: update found", data)
      else return
      let msgAction = false
      let checkForMsg = false
      let gotError = false
      var maxCount = data.reason.length
      for (var i in data.reason)
        switch (data.reason[i]) {
          // friend request
          case "requestFriend":
            this.configService.friendList = this.list.pushFirst(data.data[i], this.configService.friendList)
            this.configService.storage_save("friendList", this.configService.friendList, false)
            try {
              this.configService.presentToast("newUpdate", this.language["friendRequest"] + data.data[i].username, "", "top", 4000)
            } catch (err) {
              console.warn("NO LANGUAGE SET IN UPDATE-SERVICE", this.language)
              this.getLang()
            }
            break
          case "acceptFriend":
            this.list._approve_friend(data.data[i].username, data.data[i].pubKey)
            try {
              this.configService.presentToast("newUpdate", this.language["friendAccept"]["1"] + data.data[i].username + this.language["friendAccept"]["2"], "", "top", 4000)
            } catch (err) {
              console.warn("NO LANGUAGE SET IN UPDATE-SERVICE", this.language)
              this.getLang()
            }
            break
          case "deleteFriend":
            this.list._delete_friend(data.data[i].username)
            this.configService.removeChatData(data.data[i].username, true)
            try {
              this.configService.presentToast("newUpdate", this.language["friendDeclined"]["1"] + data.data[i].username + this.language["friendDeclined"]["2"], "", "top", 4000)
            } catch (err) {
              console.warn("NO LANGUAGE SET IN UPDATE-SERVICE", this.language)
              this.getLang()
            }
            break

          // chat messages
          case "submitData":
            msgAction = true
            checkForMsg = true
            break
          case "recievedData":
            this.recievedData(data.data[i])
            break
          case "submitFile":
            msgAction = true
            this.checkForFiles = true
            break

          // pgp keys
          case "newPubKey":
            this.list.changeFriendSettings(data.data[i].username, "pubKey", data.data[i].pubKey, true)
            //this.configService.presentToast("newUpdate", "Got new public PgP-key from " + data.data[i].username, "", "top", 2000)
            break
          case "deletePubKey":
            this.list.changeFriendSettings(data.data[i].username, "pubKey", "", true)
            //this.configService.presentToast("newUpdate", "User " + data.data[i].username + " is no longer logged in! Deleted his PgP-Key," +
            //  "so you can´t write him any more messageg until he logs in again", "", "top", 3000)
            break

          // newAvatar
          case "newAvatar":
            this.list.changeFriendSettings(data.data[i].username, "icon", data.data[i].icon, true)
            //this.configService.presentToast("newUpdate", "User " + data.data[i].username + " has changed his avatar!", "", "top", 3000)
            break

          // resendMsg
          case "resendMsg":
            this.resendMsg(data.data[i].username, data.data[i].msgId)
            break

          // getNewPushToken
          case "getNewPushToken":
            return this.resetPushKey()

          default:
            console.error("ERROR | EMPTY UPDATE MSG | data(obj)", data)
            gotError = true
            break
        }
      if (gotError) return

      if (msgAction) {
        if (checkForMsg) this.checkForMsg()
        else if (!checkForMsg && this.checkForFiles) {
          this.checkForDownload()
          //this.checkForMsg()
        }

        if (this.backgroundMode.isActive())
          this.localNotifications.schedule({
            id: 1,
            text: 'New Message!',
            foreground: true,
            sound: this.configService.settings.sound.file
          });

        this.events.publish('newMsg', {})
        this.events.publish('doLoadingFriendList', true)
      } else {
        this.events.publish('refreshFriendlist', {})
      }
      this.subscibtion.unsubscribe()
    })
  }

  recievedData(_data) {
    // msg was successfuly decrypted now send "recieved" to server / friend
    this.request.sendRequest({ id: _data.id }, "/recievedDataCallback", _data.from, "",
      (response) => console.log("Msg with ID " + _data.id + " deleted from server", response)
    )
    // save double-checkmar
    this.configService.userData_update(_data.id, _data.from, "status", "done-all")

    // refresh after all msg are processed
    this.events.publish('updateMsg', { msgId: _data.id, status: "done-all" })
  }



  //########################################################
  //##############        msg-system        ################
  //########################################################


  private canCheckForMsg: boolean = true
  checkForMsg() {
    if (this.canCheckForMsg) {
      this.canCheckForMsg = false
      // get ALL chat messages from server AT ONCE ? maybe not best choise
      this.request.getChat().subscribe((data) => {
        console.log("get chat", data)
        //this.msgTimer = 0
        this.holdMsgToProcess = data.result
        this.currentProcessInt = 0
        this.wasPlayed = false
        this.startProcessMsg()
        this.events.publish('refreshFriendlist', {})
      })
    }
  }

  startProcessMsg() {
    //console.log("msgDATA ---------->this.holdMsgToProcess[this.currentProcessInt]",this.holdMsgToProcess[this.currentProcessInt])
    //console.log("msgDATA ---------->this.currentProcessInt",this.currentProcessInt)
    //move user on first place on friendList
    if (!this.holdMsgToProcess[this.currentProcessInt]) {
      this.canCheckForMsg = true
      return
    }
    console.log("startProcessMsg --->this.holdMsgToProcess[this.currentProcessInt].data.type",this.holdMsgToProcess[this.currentProcessInt].data.type)
    if (this.holdMsgToProcess[this.currentProcessInt].data.type == "text")
      this.pgp.decrypt_msg(this.holdMsgToProcess[this.currentProcessInt].data.msg, (decoded: any) => {
        console.log("startProcessMsg --->pgp.decrypt_msg -->decoded", decoded)
        if (!decoded) return this.resendMsg(this.holdMsgToProcess[this.currentProcessInt].from, this.holdMsgToProcess[this.currentProcessInt].data.msgId)
        if (decoded.err) {
          if (decoded.err == 'options')
            return this.configService.presentToast("PgPerr", this.language["backend"]["pgpOptionsErr"], "", "middle", 3000)
          if (decoded.err == 'decryption')
            return this.configService.presentToast("PgPerr", this.language["backend"]["pgpDecryptionErr"], "", "middle", 3000)
          if (decoded.err == 'noData')
            return this.configService.presentToast("PgpErr", this.language["backend"]["pgpNoDataErr"], "", "middle", 3000)
        }
        this.processMsg(this.holdMsgToProcess[this.currentProcessInt], decoded)
      }).catch((err) => {
        console.log("PGP Decryption | error", err)
        this.markMsgForResend(this.holdMsgToProcess[this.currentProcessInt])
      })
    else
      this.processMsg(this.holdMsgToProcess[this.currentProcessInt], this.holdMsgToProcess[this.currentProcessInt].data.msg + '<br><i style="font-size:8px;">(File)</i>')
  }

  async processMsg(_msgData, decoded) {
    
    this.list.moveFirst(this.holdMsgToProcess[this.currentProcessInt].from, false)
    // set "new msg" in friendList if sender is not currentChatPartner
    if (this.configService.currentChatFriend != this.holdMsgToProcess[this.currentProcessInt].from)
      this.list.changeFriendSettings(this.holdMsgToProcess[this.currentProcessInt].from, "lastText", "New Message", true)

    // msg was successfuly decrypted now send "recieved" to server / friend
    this.request.sendRequest({ id: _msgData.data.msgId }, "/recievedData", _msgData.from, "",
      (response) => console.log("Msg with ID " + _msgData.data.msgId + " marked as READ on server")
    )

    let fileStatus = "none"
    if (_msgData.data.type != "text") {
      const expressionToCheck =
        _msgData.data.type == "photo" ?
          _msgData.data.type + '#' + _msgData.data.msgId :
          _msgData.data.type + '#' + _msgData.data.fileName + '#' + _msgData.data.msgId

      console.log("msg type is not text | check for saved image", expressionToCheck)
      await this.configService.storage_get(expressionToCheck).then((res) => {
        console.log("msg type is not text | storage get value", res)
        if (res)
          fileStatus = "finished"
      })
    }

    // set wasRead for msg if user is not in chat
    if (!this.backgroundMode.isActive() && this.configService.currentChatFriend != _msgData.from)
      this.configService.setWasRead(_msgData.from)

    console.log("save msgDFata", _msgData)
    this.configService.userData_save(
      decoded,
      _msgData.data.msgId,
      _msgData.from,
      _msgData.data.type,
      _msgData.data.fileName,
      _msgData.timeStamp,
      false,
      _msgData.data.hasLink,
      () => {

        const timeStampForChat = this.configService.dateConverter(_msgData.timeStamp)
        this.events.publish('addMsg', {
          from: _msgData.from,
          data: decoded,
          wasSend: false,
          msgId: _msgData.data.msgId,
          type: _msgData.data.type,
          fileStatus: fileStatus,
          fileName: _msgData.data.fileName,
          timeStamp: timeStampForChat.substring(0, timeStampForChat.length - 3),
          hasLink: _msgData.data.hasLink,
          timeStampCheck: timeStampForChat,
        })

        if (!this.configService.settings.sound.mute && !this.wasPlayed) {
          this.configService.ringtone_play()
          this.wasPlayed = true
        }

        this.showMsgInfo(_msgData.from)

        this.currentProcessInt++

        //check is all msg are processed and then start img download 
        if (this.checkForFiles && this.currentProcessInt == this.holdMsgToProcess.length)
          this.checkForDownload()

        if (this.currentProcessInt == this.holdMsgToProcess.length) {
          this.holdMsgToProcess = []
          if (this.checkForFiles)
            this.checkForFiles = false
          this.canCheckForMsg = true
        } else {
          this.startProcessMsg()
        }

      }
    )

  }

  showMsgInfo(_from) {
    this.configService.presentToast(
      "newUpdate",
      _from,
      "New message from " + _from,// + this.domSanitizer.bypassSecurityTrustHtml('<ion-button color="dark" (click)="goToTest">GOTO</ion-button>'), 
      "top",
      2000
    )
    /*this.configService.presentToastWithGoTo(
      "newUpdate",
      _from,
      this.language["newMessage"] + _from + '<ion-button color="dark" (click)="goToTest">GOTO</ion-button>',
      "top",
      2000
    )*/
  }

  //########################################################
  //##############        file-system        ###############
  //########################################################

  checkForDownload() {
    this.request.getDownloads().subscribe(async (data) => {
      //console.log("checkForDownload data",data)
      if (data) {
        for (var i in data)
          this.mediaService.createNewDownload(data[i].msgId, data[i].friendName, data[i].maxChunks, data[i].type, data[i].fileName, data[i].dataHash)
        /*setTimeout(()=>{
          this.canCheckForMsg = true
        },5000)*/
      }
    })
  }


  //########################################################
  //##############       PgP error-system        ###########
  //########################################################

  markMsgForResend(_msgData) {
    console.log("PGP-KEY_ERROR ... try to obtain last msg again with new pgp-key", _msgData)

    this.currentProcessInt++
    if (this.currentProcessInt == this.holdMsgToProcess.length) {
      this.holdMsgToProcess = []
      if (this.checkForFiles)
        this.checkForFiles = false
    } else {
      this.startProcessMsg()
    }

    this.request.sendRequest({ id: _msgData.data.msgId, pubKey: this.pgp.pubKeyObj }, "/resendMsg", _msgData.from, "",
      (response) => console.log("Msg with ID " + _msgData.data.msgId + " marked for RESEND on server")
    )
  }

  resendMsg(_friendName, _msgId) {
    this.configService.userData_get(_friendName, async (data: any) => {
      const msg = data.find(data => data.msgId == _msgId)
      if (msg) {
        // encrypt with pgp
        this.pgp.encrypt_msg(msg, _friendName, (encrypted: any) => {
          if (!encrypted) return this.configService.presentToast("noPgP", this.language["pgpFail"], "", "middle", 3000)
          // send encrypted data to server
          this.request.sendRequest({ msg: encrypted, msgId: _msgId, type: "text" }, "/submitData", _friendName, "", (result) => {
            // request was successfuly, update msg status
            console.log("RESEND MSG | msg was send", _friendName, _msgId)
          })
        })
      }
    })
  }



  //###############################################################
  //######################## RESET PUSH ############################
  //###############################################################

  resetPushKey() {
    console.log("RESEND PUSH TOKEN | old token", window.localStorage.getItem("gcmKey"))
    this.events.publish('resetPushToken', {})
  }
}
